import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

import { firestore } from '../../firebase/firebase.utils';

class BlogItemPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            title: '',
            date: '',
            author: '',
            content: '',
            heroImage: '',
            bodyImage: '',
        };
    }

    componentDidMount () {
        try {
            // Load blog post by slug
            firestore
                .collection('blog')
                .where('slug', '==', this.props.match.params.slug)
                .get()
                .then(snapshot => {
                    const doc = snapshot.docs.length > 0 ? snapshot.docs[0] : null;

                    if (doc) {
                        this.setState({ ...doc.data() });
                    }
                });
        } catch (error) {
            console.log(`Error loading blog post: ${error}`);
        }
    }

    render () {
        const { title, date, author, content, heroImage, bodyImage } = this.state;

        return (
            <div>
                <HeaderBar />

                <main className="main-content">
                    <section className="section bg-white">
                        <div className="container">
                            {(!title && !content) &&
                                <div className="text-center mt-8">
                                    <h2>Blog Post Not Found</h2>
                                    <p><a href="#" onClick={e => { e.preventDefault(); history.back() }}>Go Back</a></p>
                                </div>
                            }

                            {(title && content) &&
                                <div>
                                    <div className="text-center mt-8">
                                        <h2 className="ft-tungsten-md" style={{ fontSize: '60px' }}>{title}</h2>
                                        <p className="ft-gotham-book">{date} by {author}</p>
                                    </div>

                                    <div className="text-center my-8">
                                        <img className="rounded-md" src={heroImage} alt="Blog Hero Image" />
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10 mx-auto ft-gotham-book"  dangerouslySetInnerHTML={{__html: content }} />
                                    </div>

                                    {bodyImage &&
                                        <div className="text-center my-6">
                                            <img className="rounded-md" src={bodyImage} alt="Blog Body Image" />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default BlogItemPage;
