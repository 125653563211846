import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const CreativeDesignServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/creative-design-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>CREATIVE DESIGN</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                Lunar Cow Publishing’s award-winning design staff combines innovation with the latest technology to deliver the best possible product to our clients. Our team uses benchmarking and creative sharing to identify creative trends in the publication industry. Our design team uses the latest hardware, all-in-one systems as well as cloud-based software, ensuring that we bring fresh ideas and cutting-edge technology solutions to your publication.
                            </p>
                            <p>
                                As we design your publication, we will focus on creating an engaging, contemporary, viewer-friendly design that identifies key factors of influence which will enhance the experience of your members, visitors and community. This leads to an increased awareness of your partners, members and region, and encourages potential visitors to plan trips in and around your area.
                            </p>
                            <p>
                                The Lunar Cow Publishing design staff will work to create a lifestyle orientation for your publication, allowing it to stand apart from other publications in your community. Our goal is to strengthen the branding and positioning of your publication.
                            </p>
                            <p>
                                First, we produce eye-catching covers that will entice a reader to pick up your publication and regard it as a useful tool to help them engage your members and partners of your organization.  Secondly, based on the cover, we create striking page styles that will flow through your publication, enhancing the best your community has to offer. Along the way, we will make recommendations to improve the overall look of your publication while providing working concepts for your review and revisioning.
                            </p>
                            <p>
                                Our goal is to increase the value of your publication in your community and better convey to the readers the assets of your community. We will increase the effectiveness of your publication to maximize your brand impact for your members, visitors, and the community.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default CreativeDesignServicePage;
