import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const TimelinesProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>TIMELINES</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/infographics/timeline.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/infographics/timeline.png" alt="Timeline Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Lunar Cow Publishing will provide the experience and expertise necessary to design, develop, implement, and manage your campaign. Your project will be divided into five milestones to ensure clear, one-to-one communication with your team and your Lunar Cow Publishing project manager. Each milestone is assigned specific deliverables along with timelines. Lunar Cow Publishing will not move on to the next milestone until we have surpassed your expectations on the current milestone. This approach guarantees that we will exceed your expectations at the completion of the project.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default TimelinesProcessPage;
