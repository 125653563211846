import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

import { firestore, functions } from '../../firebase/firebase.utils';
import { states } from '../../helpers/types';
import uuid from 'uuid';

class ScheduleDemoPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            name: '',
            title: '',
            company: '',
            email: '',
            phone: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
            info: '',

            interestedInPublications: false,
            interestedInWebDevelopment: false,
            interestedInDigitalPublishing: false,
            interestedInCommunityMap: false,
            interestedInImap: false,
            interestedInAdvertisingSales: false,
            interestedInPrinting: false,

            demoMessageResult: '',
            demoMessageError: '',
        };

        this.websiteDemoRequestEmailer = functions.httpsCallable('websiteDemoRequestEmailer');
    }

    scheduleDemo = e => {
        // Prevent the page from performing a hard-refresh
        e.preventDefault();

        const {
            name, title, company, email, phone, line1, line2, city, state, zip, info,
            interestedInPublications,
            interestedInWebDevelopment,
            interestedInDigitalPublishing,
            interestedInCommunityMap,
            interestedInImap,
            interestedInAdvertisingSales,
            interestedInPrinting
        } = this.state;

        // Clear alerts on form-submit
        this.setState({ demoMessageResult: '', demoMessageError: '' });

        if (!name && !email) {
            this.setState({ demoMessageError: 'You must fill out the required fields before submitting the form' });
            return;
        }

        // Add a new quote request to the database
        firestore
            .collection('websiteDemoRequests')
            .add({
                name,
                title,
                company,
                email,
                phone,
                line1,
                line2,
                city,
                state,
                zip,
                info,

                interestedInPublications,
                interestedInWebDevelopment,
                interestedInDigitalPublishing,
                interestedInCommunityMap,
                interestedInImap,
                interestedInAdvertisingSales,
                interestedInPrinting
            })
            .then(() => {
                this.websiteDemoRequestEmailer({
                    name,
                    title,
                    company,
                    email,
                    phone,
                    line1,
                    line2,
                    city,
                    state,
                    zip,
                    info,

                    interestedInPublications,
                    interestedInWebDevelopment,
                    interestedInDigitalPublishing,
                    interestedInCommunityMap,
                    interestedInImap,
                    interestedInAdvertisingSales,
                    interestedInPrinting
                });
            });

        // Clear out the data in the state once we're done
        this.setState({
            name: '',
            title: '',
            company: '',
            email: '',
            phone: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
            info: '',

            interestedInPublications: false,
            interestedInWebDevelopment: false,
            interestedInDigitalPublishing: false,
            interestedInCommunityMap: false,
            interestedInImap: false,
            interestedInAdvertisingSales: false,
            interestedInPrinting: false,
            demoMessageResult: 'You have successfully submitted a demo request. We will get back to you as soon as possible'
        });
    };

    render () {
        // Extract the form elements from the state
        const {
            name,
            title,
            company,
            email,
            phone,
            line1,
            line2,
            city,
            state,
            zip,
            info,

            interestedInPublications,
            interestedInWebDevelopment,
            interestedInDigitalPublishing,
            interestedInCommunityMap,
            interestedInImap,
            interestedInAdvertisingSales,
            interestedInPrinting
        } = this.state;

        return (
            <div>
                <HeaderBar />

                <header className="header text-white" style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/demo.png)' }}>
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>SCHEDULE A DEMO</h1>
                            </div>
                        </div>
                    </div>
                </header>

                <main className="main-content">
                    <section className="section bg-white">
                        <div className="container">
                            {this.state.demoMessageResult &&
                                <div className="row">
                                    <div className="col-md-12 mx-auto" style={{ paddingLeft: '0px' }}>
                                        <div className="alert alert-success d-on-success">{this.state.demoMessageResult}</div>
                                    </div>
                                </div>
                            }

                            {this.state.demoMessageError &&
                                <div className="row">
                                    <div className="col-md-12 mx-auto" style={{ paddingLeft: '0px' }}>
                                        <div className="alert alert-danger d-on-error">{this.state.demoMessageError}</div>
                                    </div>
                                </div>
                            }

                            <div className="row text-center">
                                <form onSubmit={this.scheduleDemo}>
                                    <div className="row">
                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                value={name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            />
                                        </div>

                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                placeholder="Title"
                                                value={title}
                                                onChange={e => this.setState({ title: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            value={company}
                                            onChange={e => this.setState({ company: e.target.value })}
                                            style={{ border: '1px solid #bbb' }}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={e => this.setState({ email: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            />
                                        </div>

                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="phone"
                                                placeholder="Phone"
                                                value={phone}
                                                onChange={e => this.setState({ phone: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="addressOne"
                                                placeholder="Address 1"
                                                value={line1}
                                                onChange={e => this.setState({ line1: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                                />
                                        </div>

                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="addressTwo"
                                                placeholder="Address 2"
                                                value={line2}
                                                onChange={e => this.setState({ line2: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                                />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="city"
                                            placeholder="City"
                                            value={city}
                                            onChange={e => this.setState({ city: e.target.value })}
                                            style={{ border: '1px solid #bbb' }}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-12 col-md-6">
                                            <select
                                                className="form-control"
                                                value={state}
                                                onChange={e => this.setState({ state: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            >
                                                {states.map(state => (
                                                    <option key={uuid()} value={state.abbr}>{state.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group col-12 col-md-6">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="zip"
                                                placeholder="Zip"
                                                value={zip}
                                                onChange={e => this.setState({ zip: e.target.value })}
                                                style={{ border: '1px solid #bbb' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            name="extra_information"
                                            placeholder="Additional Info"
                                            rows="3"
                                            value={info}
                                            onChange={e => this.setState({ info: e.target.value })}
                                            style={{ border: '1px solid #bbb' }}
                                        />
                                    </div>
                                </form>

                                <div className="col-lg-5 mx-auto">
                                    <div className="form-group">
                                        <p className="text-left">Type of services you are interested in</p>

                                        <div className="custom-controls-stacked">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInPublications}
                                                    onChange={() => this.setState({ interestedInPublications: !interestedInPublications })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInPublications: !interestedInPublications })}
                                                >
                                                    Publications
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInWebDevelopment}
                                                    onChange={() => this.setState({ interestedInWebDevelopment: !interestedInWebDevelopment })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInWebDevelopment: !interestedInWebDevelopment })}
                                                >
                                                    Web Development
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInDigitalPublishing}
                                                    onChange={() => this.setState({ interestedInDigitalPublishing: !interestedInDigitalPublishing })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInDigitalPublishing: !interestedInDigitalPublishing })}
                                                >
                                                    Digital Publishing (GoGuide)
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInCommunityMap}
                                                    onChange={() => this.setState({ interestedInCommunityMap: !interestedInCommunityMap })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInCommunityMap: !interestedInCommunityMap })}
                                                >
                                                    Community Map
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInImap}
                                                    onChange={() => this.setState({ interestedInImap: !interestedInImap })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInImap: !interestedInImap })}
                                                >
                                                    iMap
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInAdvertisingSales}
                                                    onChange={() => this.setState({ interestedInAdvertisingSales: !interestedInAdvertisingSales })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInAdvertisingSales: !interestedInAdvertisingSales })}
                                                >
                                                    Advertising Sales
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={interestedInPrinting}
                                                    onChange={() => this.setState({ interestedInPrinting: !interestedInPrinting })}
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    onClick={() => this.setState({ interestedInPrinting: !interestedInPrinting })}
                                                >
                                                    Printing
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-lg btn-block btn-primary"
                                        type="submit"
                                        onClick={this.scheduleDemo}
                                    >
                                        Schedule Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default ScheduleDemoPage;
