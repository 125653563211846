import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const StoryPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/brand-story.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>BRAND STORY</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>Lunar Cow Publishing was created from the mind of a young boy who grew up in the Ozark Mountains in Arkansas. He was fascinated with space, exploration, farming, and fairy tales. The idea gave birth to a marketing communications company that specializes in creativity, awareness and branding. Our brand depicts the tale and its icons to resonate the concept of The Cow Jumping Over the Moon.</p>
                            <p>We shaped the Lunar Cow Brand Story with familiar elements that interested us like the moon, farm, and outer space, and combined them in a way that would be familiar but also new and unique. Each element contains the symbolism that is important to us. Space represents the limitless possibility of creative ideas and a quest to explore new frontiers. The moon represents change and the cycle of ideas. The fairy-tale aspect of our brand represents our appreciation for childlike curiosity and the power of imagination. The farm represents the value of hard work and how essential it is to accomplish anything of measure.</p>
                            <p>Since the very beginning, we have been telling our unique brand story. As we grow, new team members and clients help us expand the story by adding new elements. A truly interesting tale has already begun to unfold, but like any good story, there is always another chapter waiting to be written. We look forward to continuing that story with you.</p>
                            <p>In March 2000, Lunar Cow was born with a focus on marketing our services to amusement parks, zoos, science centers, animal training properties and aquariums. From those humble beginnings, our company has grown into a destination marketing company providing services to the travel and tourism industry.</p>
                            <p>Today, Lunar Cow Publishing has over 400 clients in the United States, representing chambers of commerce along with convention and visitors bureaus. Our company has evolved into a technology company while also providing traditional publishing services.</p>
                            <p>Our company continues to expand with the publishing of relocation guides, community guides, visitor guides, business directories, activities guides, outdoor guides, and dining guides as well as community maps. These core publishing services are complemented with our proprietary mobile interactive technologies, which provide leading-edge, core differentiation for our clients while bringing tremendous value to the members, visitors, and the communities they represent.</p>
                            <p>Every day, we pay homage to the fairy tale of The Cow Jumping Over the Moon.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default StoryPage;
