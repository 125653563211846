import React from 'react';

const PreFooter = () => (
    <section
        className="section text-center bg-white bg-img-bottom"
        style={{
            backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/parallax/footer.png)',
            height: '350px',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }}
    />
);

export default PreFooter;
