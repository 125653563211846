import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const AdSalesProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>ADVERTISING SALES</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-advertising-model.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-advertising-model.png" alt="Ad Sales Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Lunar Cow Publishing utilizes a permission-based sales philosophy which allows our sales system to produce results up to 30 percent higher than traditional door-to-door or face-to-face advertising sales. We incorporate the six channels of effective sales promotions and communications, allowing our team of dedicated advertising sales specialists to produce results.
                            </p>
                            <p>
                                Advertising sales project timelines with detailed milestones will be defined shortly after the Barnstorming Summit planning session. Special care will be given in planning advertising sales timeframes that include selling, art collection and payment dates to ensure that our compilation and publication processes go smoothly.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default AdSalesProcessPage;
