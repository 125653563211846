import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ReleaseFormPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>RELEASE FORM</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <p>1.(ADVERTISER) has contracted with Lunar Cow Publishing, Inc. to perform the following work for ADVERTISER: Print of display advertisement for the Map/Guide/Directory.</p>

                            <br />

                            <p>2. Upon completion of the work described above, Lunar Cow Publishing submitted to ADVERTISER, a proof/concept of the final product. ADVERTISER hereby acknowledges that it has received the proof/concept and has reviewed the proof/concept thoroughly. ADVERTISER agrees that the proof/concept is accurate, satisfies the requirements of ADVERTISER and grants permission to Lunar Cow Publishing to publish the work.</p>

                            <br />

                            <p>3. By signing the final proof/concept the ADVERTISER hereby releases and forever discharges the VENDOR and any parent, subsidiary, sister, predecessor or successor companies and any shareholders, directors, officers, employees and agents of such party and their respective heirs, legal representatives, successors and assigns (collectively, the "RELEASED PARTIES"), from any and all errors with layout, color, position, clarity, resolution, advertising copy, advertising text, advertising artwork, advertising listings, listing of information, listing of events, activities, lodging, restaurants, destinations, member information, partner information, photographs, photograph images, reverse photograph, captions of photographs, quality of photographs, incorrect advertising, size of advertising, duplication of advertising, non-placement of advertising, omissions of advertising, lack of proofs, lack of signed off proofs, spelling errors, syntax errors, copy errors, formatting errors, copy placement errors, overwriting errors, category placement errors, claims, controversies, actions, demands, expenses, rights, debts, obligations, damages, liabilities, duties, attorneys' fees, contacts, and cause of action, of every kind and nature whatsoever, whether now known or unknown, fixed or contingent, and whether at law or in equity, which such party now has, has had, or hereafter may have against the RELEASED PARTIES, arising out of any matter, cause or thing up to the date of this Agreement, including but not limited to, any claims arising out of the obligations and liabilities of the debtors due to the printing services.</p>

                            <br />

                            <p>4. By the ADVERTISER signing this release agreement, the client release Lunar Cow Publishing for all and any liabilities associated with the design of the advertiser's ad, layout of advertisement, format of advertisement, colors used for advertisement, images used in advertisement, photos used in advertisement, placement of advertisement both in print and digital versions. All liabilities associated with the design, layout, color, format, quality, resolution, clarity, position of advertisement is transferred and accepted responsibilities of the ADVERTISER.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ReleaseFormPage;
