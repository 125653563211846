import React from 'react';
import uuid from 'uuid';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

// Import our animations
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Swing from 'react-reveal/Swing';

import { firestore, functions } from '../../firebase/firebase.utils';

class IndexPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            posts: [],

            activeSlide: 0,

            // Newsletter data
            newsletterEmail: '',
            newsletterMessageResult: '',

            // Contact Us data
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactMessage: '',
            contactMessageResult: '',
        };

        this.websiteNewsletterEmailer = functions.httpsCallable('websiteNewsletterEmailer');
        this.websiteContactEmailer = functions.httpsCallable('websiteContactEmailer');
    }

    componentDidMount () {
        try {
            firestore
                .collection('blog')
                .orderBy('date', 'desc')
                .limit(3)
                .get()
                .then(snapshot => {
                    const posts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    this.setState({ posts });
                });
        } catch (error) {
            console.log('Could not load blog posts');
        }
    }

    submitNewsletter = e => {
        e.preventDefault();

        const { newsletterEmail } = this.state;

        // Add a new newsletter subscriber to the database
        firestore
            .collection('newsletterSubscribers')
            .add({ email: newsletterEmail })
            .then(() => {
                // Fire the emailer
                this.websiteNewsletterEmailer({ email: newsletterEmail });
            });

        // Clear out the data in the state once we're done
        this.setState({
            newsletterEmail: '',
            newsletterMessageResult: 'You have successfully subscribed to the newsletter. We send out an email about once a month'
        });
    };

    submitContactForm = e => {
        e.preventDefault();

        const { contactName, contactEmail, contactSubject, contactMessage } = this.state;

        // Add a new contact request to the database
        firestore
            .collection('contactUsMessages')
            .add({
                name: contactName,
                email: contactEmail,
                subject: contactSubject,
                message: contactMessage
            })
            .then(() => {
                this.websiteContactEmailer({
                    name: contactName,
                    email: contactEmail,
                    subject: contactSubject,
                    message: contactMessage
                });
            });

        // Clear out the data in the state once we're done
        this.setState({
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactMessage: '',
            contactMessageResult: 'You have successfully sent a contact request. We will get back to you as soon as possible'
        });
    };

    render () {
        const { posts, activeSlide } = this.state;

        return (
            <div>
                <HeaderBar />

                <main className="main-content" id="home">
                    {/** SECTION: Header Slider */}

                    <Slider
                        slidesToShow={1}
                        slidesToScroll={1}
                        dots={false}
                        fade={true}
                        infinite={true}
                        speed={1000}
                        arrows={false}
                        autoplay={true}
                        autoplaySpeed={4000}
                        pauseOnHover={false}
                        swipeToSlide={false}
                        beforeChange={(current, next) =>
                            this.setState(() => ({ activeSlide: next }))
                        }
                    >
                        <div>
                            <section
                                className={`section text-center ${activeSlide == 0 ? 'pan-in' : ''} py-5`}
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/slider/slide-1.png)', height: '100vh' }}
                            />
                            <section
                                className="section"
                                style={{ position: 'absolute', top: '0', display: 'block', height: '100vh', width: '100vw', bottom: '0' }}
                            >
                                <div className="container text-center">
                                    <div className="row h-100">
                                        <div className="col-lg-8 mx-auto align-self-center">
                                            <p>
                                                <img
                                                    src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/images/logos/lunarcow/LC-Publishing-logo-white-md.png"
                                                    className={`${activeSlide == 0 ? 'slide-in-logo' : ''}`}
                                                    alt="Lunar Cow Publishing"
                                                    style={{ height: 'auto', width: '100%', opacity: 0 }}
                                                />
                                            </p>
                                        </div>
                                        <div className="col-12 align-self-end text-center mt-9">
                                            <a className="scroll-down-1 scroll-down-white" href="#about">
                                                <span
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderLeft: '2px solid #fff',
                                                        borderBottom: '2px solid #fff'
                                                    }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div>
                            <section
                                className={`section text-center ${activeSlide == 1 ? 'pan-out' : ''} py-5`}
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/slider/slide-2.jpg)', height: '100vh' }}
                            />
                            <section
                                className="section"
                                style={{ position: 'absolute', top: '0', display: 'block', height: '100vh', width: '100vw', bottom: '0' }}
                            >
                                <div className="container">
                                    <header className="section-header">
                                        <h2 className={`text-white fw-800 ft-tungsten-md ${activeSlide == 1 ? 'slide-in-left' : ''}`} style={{ fontSize: '9vh', opacity: 0 }}><strong>AWARD-WINNING PUBLICATIONS</strong></h2>
                                    </header>
                                </div>
                            </section>
                        </div>

                        <div>
                            <section
                                className={`section text-center ${activeSlide == 2 ? 'pan-in' : ''} py-5`}
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/slider/slide-3.jpg)', height: '100vh' }}
                            />
                            <section
                                className="section"
                                style={{ position: 'absolute', top: '0', display: 'block', height: '100vh', width: '100vw', bottom: '0' }}
                            >
                                <div className="container">
                                    <header className="section-header">
                                        <h2 className={`text-white fw-800 ft-tungsten-md ${activeSlide == 2 ? 'slide-in-bottom' : ''}`} style={{ fontSize: '9vh', opacity: 0 }}><strong>PROFITABLE ADVERTISING SALES</strong></h2>
                                    </header>
                                </div>
                            </section>
                        </div>

                        <div>
                            <section
                                className={`section text-center ${activeSlide == 3 ? 'pan-out' : ''} py-1`}
                                data-scrim-top="4"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/slider/slide-4.jpg)', height: '100vh' }}
                            />
                            <section className="section" style={{ position: 'absolute', top: '0', display: 'block', height: '100vh', width: '100vw', bottom: '0' }}>
                                <div className="container">
                                    <header className="section-header zoom-fixed-text">
                                        <h2 className={`text-white fw-800 ft-tungsten-md ${activeSlide == 3 ? 'slide-in-right' : ''}`} style={{ fontSize: '9vh', opacity: 0 }}><strong>MOBILE TECHNOLOGIES</strong></h2>
                                    </header>
                                </div>
                            </section>
                        </div>
                    </Slider>

                    {/** TODO: SECTION: About Us */}
                    <section className="section" id="about" style={{ backgroundColor: '#fff' }}>
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>ABOUT US</h1>
                            </header>

                            <div className="row align-items-center text-center">
                                <div className="col-md-12 mb-6">
                                    <p
                                        className="ft-gotham-book"
                                        style={{ fontSize: '17px' }}
                                    >
                                        Here on the farm, our ranchers and farmhands have a customer-centric operating philosophy. Nothing exists without our clients; they are the center of what we do. Our systems, methods, processes, and technology have been designed to champion our clients; their goals become our goals. Our staff is our greatest asset, and they take great pride in collaborating with our clients to exceed expectations and achieve breakthroughs in performance and results. We operate in a mission-centric process where building trust, working relationships, bonding and collaboration are pivotal to our success.
                                    </p>
                                </div>
                            </div>

                            <div className="row align-items-center mt-6">
                                <div className="col-md-4">
                                    <Slide left>
                                        <Link to="/story" className="text-default">
                                            <div className="media align-items-center mb-5">
                                                <div className="media-body text-right mr-5">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>BRAND STORY</h5>
                                                </div>
                                                <div>
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/brand-story.svg"
                                                            alt="Brand Story Icon Thumbnail"
                                                            style={{ height: '60%' }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/farm" className="text-default">
                                            <div className="media align-items-center mb-5">
                                                <div className="media-body text-right mr-5">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>THE FARM</h5>
                                                </div>
                                                <div>
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/the-farm.svg"
                                                            alt="The Farm Icon Thumbnail"
                                                            style={{ height: '60%' }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/farmhands" className="text-default">
                                            <div className="media align-items-center">
                                                <div className="media-body text-right mr-5">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>FARM HANDS</h5>
                                                </div>
                                                <div>
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/farmhands.svg"
                                                            alt="Farmhands Icon Thumbnail"
                                                            style={{ height: '70%' }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </Slide>
                                </div>

                                <div className="col-md-4">
                                    <div className="text-center">
                                        <img
                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/abduction.svg"
                                            style={{ width: '75%' }}
                                            alt="Cow Abduction Thumbnail"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <Slide right>
                                        <Link to="/philosophy" className="text-default">
                                            <div className="media align-items-center mb-5">
                                                <div className="mr-5">
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/business-philosophy.svg"
                                                            alt="Business Philosophy Icon Thumbnail"
                                                            style={{ height: '60%' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>BUSINESS PHILOSOPHY</h5>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/publishing" className="text-default">
                                            <div className="media align-items-center mb-5">
                                                <div className="mr-5">
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/publishing.svg"
                                                            alt="Publishing Icon Thumbnail"
                                                            style={{ height: '60%' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>PUBLISHING</h5>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/blue-ribbons" className="text-default">
                                            <div className="media align-items-center">
                                                <div className="mr-5">
                                                    <span className="iconbox iconbox-xxl" style={{ backgroundColor: '#54B6ED', border: '1px solid #000' }}>
                                                        <img
                                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/blue-ribbons.svg"
                                                            alt="Blue Ribbons Icon Thumbnail"
                                                            style={{ height: '80%' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="ft-tungsten-md" style={{ fontSize: '36px' }}>BLUE RIBBONS</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Observatory Details */}
                    <section
                        className="section text-center bg-img-bottom"
                        id="observatory"
                        style={{
                            backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/observatory.png)',
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <img
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/logo-observatory-white.svg"
                                        alt="Observatory Logo"
                                    />
                                    <br /><br /><br />
                                    <p className="text-center">
                                        <a
                                            className="btn btn-xl btn-round btn-light w-250 ft-tungsten-md"
                                            href="https://advertisers.lunarcow.com"
                                            style={{ fontSize: '25px' }}
                                        >
                                            LOGIN HERE
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Presentations */}
                    <section className="section" id="presentations">
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>PRESENTATIONS</h1>
                            </header>

                            <div className="row gap-y text-center">
                                {/*<OverPack style={{ overflow: 'hidden' }}>*/}
                                 {/** <ScrollOverPack
                                    id="presentation-section"
                                    className="presentation-section"
                                    replay
                                    playScale="20vh"
                                >
                                     {/** <QueueAnim key="1">key="queue" delay={300} leaveReverse */}
                                     <Flip right delay={200}>
                                        <div key="0" className="col-md-3 col-xl-3 feature-1" style={{ display: 'inline-block', verticalAlign: 'middle', float: 'none' }}>
                                            <a href="https://presentation.lunarcow.com/brochure" target="_blank">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/corporate-brochure.svg"
                                                    alt="Corporate Brochure Presentation Thumbnail"
                                                    style={{ height: '100%', width: '75%' }}
                                                />
                                            </a>
                                        </div>
                                    </Flip>

                                    <Flip bottom delay={500}>
                                        <div key="1" className="col-md-3 col-xl-3 feature-1" style={{ display: 'inline-block', verticalAlign: 'middle', float: 'none' }}>
                                            <a href="https://presentation.lunarcow.com/imap" target="_blank">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/imap.svg"
                                                    alt="iMap Presentation Thumbnail"
                                                    style={{ height: '100%', width: '75%' }}
                                                />
                                            </a>
                                        </div>
                                    </Flip>

                                    <Flip top delay={800}>
                                        <div key="2" className="col-md-3 col-xl-3 feature-1" style={{ display: 'inline-block', verticalAlign: 'middle', float: 'none' }}>
                                            <a href="https://presentation.lunarcow.com/goguide" target="_blank">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/go-guide.svg"
                                                    alt="GoGuide Presentation Thumbnail"
                                                    style={{ height: '100%', width: '75%' }}
                                                />
                                            </a>
                                        </div>
                                    </Flip>

                                    <Flip left delay={1100}>
                                        <div key="3" className="col-md-3 col-xl-3 feature-1" style={{ display: 'inline-block', verticalAlign: 'middle', float: 'none' }}>
                                            <a href="https://presentation.lunarcow.com/board" target="_blank">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/board-presentation.svg"
                                                    alt="Board Presentation Thumbnail"
                                                    style={{ height: '100%', width: '75%' }}
                                                />
                                            </a>
                                        </div>
                                    </Flip>
                                     {/** </QueueAnim>
                                </ScrollOverPack>
                                {/*</OverPack>*/}
                            </div>

                            <div className="row gap-y text-center">
                                <div className="col-md-3 col-xl-3 feature-1 ft-gotham-book">
                                    <h5 className="fw-500">
                                        <a href="/corporate-brochure" target="_blank">
                                            CORPORATE BROCHURE
                                        </a>
                                    </h5>
                                </div>

                                <div className="col-md-3 col-xl-3 feature-1">
                                    <h5 className="fw-500">
                                        <a href="https://presentation.lunarcow.com/imap" target="_blank">
                                            iMAP
                                        </a>
                                    </h5>
                                </div>

                                <div className="col-md-3 col-xl-3 feature-1">
                                    <h5 className="fw-500">
                                        <a href="https://presentation.lunarcow.com/goguide" target="_blank">
                                            GO GUIDE
                                        </a>
                                    </h5>
                                </div>

                                <div className="col-md-3 col-xl-3 feature-1">
                                    <h5 className="fw-500">
                                        <a href="https://presentation.lunarcow.com/board" target="_blank">
                                            BOARD PRESENTATION
                                        </a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* SECTION: Transition: Farmhands near house */}
                    <section
                        className="section text-center h-500"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/farmhouse.jpg)' }}
                    >
                        <div className="container">
                            <div className="row gap-y text-center">
                                &nbsp;
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Portfolio (Links to actual page and sections) */}
                    <section
                        className="section text-center bg-white"
                        id="portfolio"
                    >
                        <div className="container">
                            <header className="section-header" style={{ marginBottom: '0px' }}>
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>PORTFOLIO</h1>
                            </header>
                        </div>
                    </section>

                    <section className="section p-0">
                        <div className="row no-gutters"> {/* lp-portfolio */}
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/business-directory.png"
                                        style={{ width: '100%' }}
                                        alt="Business Directory"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">BUSINESS DIRECTORY</h5>
                                        <p className="ft-gotham-book fw-500">The North Tampa Bay Chamber of Commerce</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/business-directory.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/biIUTxwjWmppgmIYaF7Q">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-profile.png"
                                        style={{ width: '100%' }}
                                        alt="Community Guide"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">COMMUNITY PROFILE</h5>
                                        <p className="ft-gotham-book fw-500">Lockhart Chamber of Commerce</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-profile.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/gPmf8HcDOc0CYni8DDWM">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/visitors-guide.png"
                                        style={{ width: '100%' }}
                                        alt="Visitor Guide"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">VISITORS GUIDE</h5>
                                        <p className="ft-gotham-book fw-500">Visit Buena Park</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/visitors-guide.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/1syWizGnYo3bd9fwCjxO">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-map.png"
                                        style={{ width: '100%' }}
                                        alt="Community Map"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">COMMUNITY MAP</h5>
                                        <p className="ft-gotham-book fw-500">Byrdstown Pickett Chamber of Commerce</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-map.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/zgt8Ig81GWoEu0s70AoQ">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/dining.png"
                                        style={{ width: '100%' }}
                                        alt="Dining Guide"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">DINING</h5>
                                        <p className="ft-gotham-book fw-500">Visit Oakland</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/dining.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/H2d96VfqBk7ZH9D3tw5e">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <div className="portfolio-1">
                                    <img
                                        className="fit-cover py-1 px-1"
                                        src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/planning-guide.png"
                                        style={{ width: '100%' }}
                                        alt="Planning Guide"
                                    />
                                    <div className="portfolio-detail">
                                        <h5 className="ft-gotham-book fw-500">PLANNING GUIDE</h5>
                                        <p className="ft-gotham-book fw-500">Corpus Christi CVB</p>
                                        <p>
                                            <a
                                                href="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/planning-guide.png"
                                                target="_blank"
                                            >
                                                <button type="button" className="btn btn-circle btn-secondary mr-3">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </a>
                                            <Link to="/portfolio/lks12vlW2qUhWlRv5LaK">
                                                <button type="button" className="btn btn-circle btn-secondary">
                                                    <i className="fa fa-external-link" />
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                        {/*
                        <div className="row no-gutters portfolio-container no-space isotope">
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/business-directory.png" alt="Business Directory" />
                                </Link>
                                <div className="image-overlay">
                                    <span className="white-box"></span>
                                    <div className="image-overlay-text">
                                        <h4><a href="https://dtcitrus.wpengine.com/blog/dt_portfolios/photography/" title="Photography">Photography</a></h4>
                                        <span className="small-line"></span>
                                                                        <p><a href="https://dtcitrus.wpengine.com/blog/dt_portfolios/photography/">Excitement</a></p>
                                                                    <ul className="links">
                                            <li><a href="https://dtcitrus.wpengine.com/wp-content/uploads/2014/09/portfolio5.jpg" className="zoom" data-gal="prettyPhoto[gallery]" title="Photography"><span className="hexagon"><span className="corner1"></span><span className="corner2"></span><i className="fa fa-search"></i></span></a></li>
                                            <li><a href="https://dtcitrus.wpengine.com/blog/dt_portfolios/photography/" className="link" title="Photography"><span className="hexagon"><span className="corner1"></span><span className="corner2"></span><i className="fa fa-external-link"></i></span></a></li>
                                        </ul>
                                    </div>
                                    <span className="border-line"></span>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-profile.png" alt="Community Guide" />
                                </Link>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/visitors-guide.png" alt="Visitor Guide" />
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/community-map.png" alt="Community Map" />
                                </Link>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/dining.png" alt="Dining Guide" />
                                </Link>
                            </div>
                            <div className="col-md-4" style={{ minHeight: '300px' }}>
                                <Link to="/portfolio">
                                    <img className="fill-parent fit-cover py-1 px-1" src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/portfolio/planning-guide.png" alt="Planning Guide" />
                                </Link>
                            </div>
                        </div>
                        */}

                    {/** TODO: SECTION: Transition Section: Get a Quote, Schedule a Demo */}
                    <section
                        className="section text-center py-10 text-white bg-img-left"
                        id="cta"
                        style={{
                            backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/call-to-action.png)',
                        }}
                    >
                        <header className="section-header">
                            <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>LET'S WORK TOGETHER</h1>
                        </header>

                        <div className="container">
                            <p className="gap-x-7">
                                <Link
                                    to="/get-quote"
                                    className="btn btn-xl dark-hover ft-tungsten-md lead-5 w-400"
                                    style={{ borderRadius: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}
                                >
                                    GET A QUOTE
                                </Link>

                                <Link
                                    to="/schedule-demo"
                                    className="btn btn-xl dark-hover ft-tungsten-md lead-5 w-400"
                                    style={{ borderRadius: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}
                                >
                                    SCHEDULE A DEMO
                                </Link>
                            </p>
                        </div>
                    </section>

                    {/** TODO: SECTION: Reports and Marketing Data */}
                    <section className="section" id="reports" style={{ backgroundColor: '#fff' }}>
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>REPORTS AND MARKETING DATA</h1>
                            </header>

                            <div className="row gap-y text-center">
                                <Flip right delay={200}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <Link to="/5-deadliest-trends">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/misc/5-trends.png"
                                                    alt="5 Deadliest Trends Thumbnail"
                                                    style={{ width: '175px' }}
                                                />
                                            </Link>
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={500}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <Link to="/10-steps">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/misc/10-steps.png"
                                                    alt="10 Steps Thumbnail"
                                                    style={{ width: '175px' }}
                                                />
                                            </Link>
                                        </p>
                                    </div>
                                </Flip>

                                <Flip top delay={800}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <Link to="/email-metrics">
                                                <img
                                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/misc/email-metrics.png"
                                                    alt="10 Steps Thumbnail 2"
                                                    style={{ width: '175px' }}
                                                />
                                            </Link>
                                        </p>
                                    </div>
                                </Flip>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Watch Our Reel */}
                    <section
                        className="section text-white bg-img-bottom"
                        id="process"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/reel.png)' }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6" />
                                <div className="col-md-6 col-xl-6">
                                    <header className="section-header">
                                        <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>WATCH OUR REEL</h1>
                                    </header>

                                    <p className="text-center text-white">
                                        <Link to="/reel">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/svg/play.svg"
                                                alt="Demo Reel Play Icon"
                                                style={{ height: '150px' }}
                                            />
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Transition Section: Nothing. Picture of people on the farm */}
                    <section
                        className="section py-10 text-white h-500"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/field.jpg)' }}
                    >
                        <div className="container" />
                    </section>

                    {/** TODO: SECTION: Testimonial Slider */}
                    <section
                        className="section text-white"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/testimonials.png)' }}
                        id="testimonials"
                    >
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>WHAT OUR CLIENTS SAY</h1>
                            </header>

                            <Slider slidesToShow={1} slidesToScroll={1} dots={false} infinite={true} speed={500} arrows={false} autoplay={true}>
                                <div className="px-6">
                                    <blockquote className="text-center">
                                        <p className="lead-2 fw-500">I'm so glad Lunar Cow found us three years ago! We've worked with them on three membership guides, and we couldn't be happier with the finished products. The entire team is top notch from the sales to the design. They make the process so smooth and painless.</p>
                                        <br />
                                        <div>
                                            <img
                                                className="rounded-circle"
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/testimonials/hope-allen.jpg"
                                                alt="Hope Allen Testimonial Thumbnail"
                                                style={{ width: '155px' }}
                                            />
                                        </div>

                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '30px', marginBottom: '0px' }}>
                                            Hope Kennedy Allen
                                        </p>
                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '18px', marginTop: '-10px' }}>
                                            business.HernandoChamber.com
                                        </p>
                                    </blockquote>
                                </div>

                                <div className="px-6">
                                    <blockquote className="text-center">
                                        <p className="lead-2 fw-500">After five years of working with Lunar Cow to produce our annual visitors guide, they continue to create visually appealing cover designs and page styles that are fresh and reflective of our brand and destination.</p>
                                        <br />

                                        <div>
                                            <img
                                                className="rounded-circle"
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/testimonials/wendy-haase.jpg"
                                                alt="Wendy Haase Testimonial Thumbnail"
                                                style={{ width: '155px' }}
                                            />
                                        </div>

                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '30px', marginBottom: '0px' }}>
                                            Wendy Haase
                                        </p>
                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '18px', marginTop: '-10px' }}>
                                            DestinationIrvine.com
                                        </p>
                                    </blockquote>
                                </div>

                                <div className="px-6">
                                    <blockquote className="text-center">
                                        <p className="lead-2 fw-500">Lunar Cow has perfected the process of producing destination visitors guides. Each year, they somehow enhance the process, making it easier on me. I am amazed at the creativity put into each and every guide they produce. We have been working with Lunar Cow for 13+ years and have received nothing short of outstanding work and customer service.</p>
                                        <br />

                                        <div>
                                            <img
                                                className="rounded-circle"
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/testimonials/kim-kislowski.jpg"
                                                alt="Kim Kislowski Testimonial Thumbnail"
                                                style={{ width: '155px' }}
                                            />
                                        </div>

                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '30px', marginBottom: '0px' }}>
                                            Kim Kislowski
                                        </p>
                                        <p className="text-white ft-gotham-medium" style={{ fontSize: '18px', marginTop: '-10px' }}>
                                            VisitChicagoSouthland.com
                                        </p>
                                    </blockquote>
                                </div>
                            </Slider>
                        </div>
                    </section>

                    {/** TODO: SECTION: Our Valued Customers. Client Logos */}
                    <section
                        className="section bg-gray py-6"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/customers.png)' }}
                        id="customers"
                    >
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>OUR VALUED CUSTOMERS</h1>
                            </header>

                            <div className="row gap-y text-center">
                                <Flip bottom delay={200}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/corpus-christi.png"
                                                alt="Corpus Christi Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={400}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/millington.png"
                                                alt="Millington Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={600}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/chicago-southland.png"
                                                alt="Chicago Southland Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>
                            </div>

                            <div className="row gap-y text-center">
                                <Flip bottom delay={800}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/north-tampa.png"
                                                alt="South Tampa"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={1000}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/cocoa-beach.png"
                                                alt="Cocoa Beach Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={1200}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/buena-park.png"
                                                alt="Buena Park Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>
                            </div>

                            <div className="row gap-y text-center">
                                <Flip bottom delay={1400}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/clearfield.png"
                                                alt="Clearfield Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={1600}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/unalaska.png"
                                                alt="Unalaska Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>

                                <Flip bottom delay={1800}>
                                    <div className="col-md-4 col-xl-4 feature-1">
                                        <p className="feature-icon">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/customers/destination-irvine.png"
                                                alt="Destination Irvine Thumbnail"
                                            />
                                        </p>
                                    </div>
                                </Flip>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Blog Section. Slider with 3 items showing */}
                    <section
                        className="section"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/blog.png)' }}
                        id="blog"
                    >
                        <div className="container">
                            <header className="section-header">
                                <h1 className="text-white ft-tungsten-md" style={{ fontSize: '65px' }}>BLOG</h1>
                            </header>

                            <div className="row gap-y">
                                {posts.map(post => (
                                    <div className="col-md-6 col-lg-4" key={uuid()}>
                                        <div className="card border hover-shadow-6" style={{ height: '100%' }}>
                                            <Link to={`/blog/${post.slug}`}>
                                                <img
                                                    className="card-img-top"
                                                    src={post.thumb}
                                                    alt="Blog Post Thumbnail"
                                                />
                                            </Link>

                                            <div className="p-6 text-center">
                                                <p className="small-5 text-lighter text-uppercase ls-2 fw-400">{post.category}</p>
                                                <h5 className="mb-0"><Link className="text-dark ft-gotham-book" to={`/blog/${post.slug}`}>{post.title}</Link></h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="text-center mt-7">
                                <Link to="/blog" className="btn btn-outline-primary px-7">View All</Link>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Transition Section: Newsletter Form */}
                    {/*data-overlay-light="7"*/}
                    <section
                        className="section py-10"
                        id="newsletter"
                        style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/newsletter.jpg)' }}
                    >
                        <div className="container text-center">
                            <Fade up>
                                <header className="section-header text-white">
                                    <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>LUNAR COW NEWSLETTER</h1>
                                    <p className="lead ft-gotham-medium">Enter your email address and submit to receive the Lunar Cow monthly newsletter.</p>
                                </header>

                                <div className="row mt-3">
                                    <form className="col-11 col-lg-8 mx-auto" onSubmit={this.submitNewsletter}>
                                        {this.state.newsletterMessageResult &&
                                            <div className="alert alert-success d-on-success">{this.state.newsletterMessageResult}</div>
                                        }

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{ display: 'block' }}>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type="text"
                                                        name="newsletter-email"
                                                        placeholder="Enter Your Email"
                                                        value={this.state.newsletterEmail}
                                                        onChange={e => this.setState({ newsletterEmail: e.target.value })}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="text-center mt-5">
                                            <button className="btn btn-lg btn-primary" type="submit">SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </Fade>
                        </div>
                    </section>

                    {/** TODO: SECTION: Get Connected. Facebook, YouTube, LinkedIn buttons */}
                    <section
                        className="section text-white py-10 bg-img-left"
                        id="get-connected"
                        style={{
                            backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/parallax/connected.png)',
                            backgroundColor: '#fff',
                            height: '774px'
                        }}
                    >
                        <div className="container text-center">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>GET CONNECTED</h1>
                            </header>

                            <div className="row gap-y text-center">
                                <div className="col-md-4 col-xl-4 feature-1 text-right">
                                    <p className="feature-icon">
                                        <a href="http://www.facebook.com/lunarcow" target="_blank">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/social/social/facebook-lg.png"
                                                alt="Facebook Icon"
                                                style={{ width: '200px' }}
                                            />
                                        </a>
                                    </p>
                                </div>

                                <div className="col-md-4 col-xl-4 feature-1">
                                    <p className="feature-icon">
                                        <a href="https://www.youtube.com/channel/UCfrh92fpEsIGjJkI-mwJgaQ" target="_blank">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/social/social/youtube-lg.png"
                                                alt="YouTube Icon"
                                                style={{ width: '200px' }}
                                            />
                                        </a>
                                    </p>
                                </div>

                                <div className="col-md-4 col-xl-4 feature-1 text-left">
                                    <p className="feature-icon">
                                        <a href="https://www.linkedin.com/company/lunar-cow-design" target="_blank">
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/lunarcow/social/social/linkedin-lg.png"
                                                alt="LinkedIn Icon"
                                                style={{ width: '200px' }}
                                            />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** TODO: SECTION: Contact Us Form */}
                    <section className="section" id="contact" style={{ backgroundColor: '#fff' }}>
                        <div className="container">
                            <header className="section-header">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>GET IN TOUCH</h1>
                                <h3 className="fw-700">(800) 594-9620</h3>
                            </header>

                            <div className="row mt-8">
                                <Zoom In>
                                    <form className="col-11 col-lg-8 mx-auto p-6" onSubmit={this.submitContactForm}>
                                        {this.state.contactMessageResult &&
                                            <div className="alert alert-success d-on-success">{this.state.contactMessageResult}</div>
                                        }

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name"
                                                    value={this.state.contactName}
                                                    onChange={e => this.setState({ contactName: e.target.value })}
                                                    style={{ border: '2px solid #bbb' }}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Your Email Address"
                                                    value={this.state.contactEmail}
                                                    onChange={e => this.setState({ contactEmail: e.target.value })}
                                                    style={{ border: '2px solid #bbb' }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name="subject"
                                                    placeholder="Subject"
                                                    value={this.state.contactSubject}
                                                    onChange={e => this.setState({ contactSubject: e.target.value })}
                                                    style={{ border: '2px solid #bbb' }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <textarea
                                                className="form-control form-control-lg"
                                                rows="4"
                                                placeholder="Your Message"
                                                name="message"
                                                value={this.state.contactMessage}
                                                onChange={e => this.setState({ contactMessage: e.target.value })}
                                                style={{ border: '2px solid #bbb' }}
                                            />
                                        </div>

                                        <Swing>
                                            <div className="text-center">
                                                <button className="btn btn-lg btn-primary" type="submit">Send Message</button>
                                            </div>
                                        </Swing>
                                    </form>
                                </Zoom>
                            </div>
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default IndexPage;
