import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ServicesPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/services.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>SERVICES</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row gap-y">

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/market" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/markets.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>MARKETS</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/publishing" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/publishing.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>PUBLISHING</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/digital-publishing" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/digital-publishing.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>DIGITAL PUBLISHING</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/creative-design" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/creative-design.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>CREATIVE DESIGN</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/advertising-sales" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/advertising-sales.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>ADVERTISING SALES</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/printing" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/printing.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>PRINTING</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/imap" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/imap.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>IMAP</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/web-development" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/web-development.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>WEB DEVELOPMENT</h3>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <Link
                                to="/services/kiosk" className="card text-white text-center bg-img py-8"
                                style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/kiosks.png)' }}
                            >
                                <div className="position-relative">
                                <h3 className="fw-800 mb-0 ft-tungsten-md" style={{ fontSize: '40px' }}>KIOSKS</h3>
                                </div>
                            </Link>
                        </div>


                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ServicesPage;
