import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const PublishingServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/publishing-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>PUBLISHING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                We have a sincere passion for publishing visitor guides, activity guides, adventure guides, community guides, relocation guides, dining guides, business directories and community maps. Publishing on The Farm consists of award-winning graphic design, advertising sales, editorial production, creating compelling images, proofing, printing with master-certified printers, digital publishing and interactive mobile mapping technologies.
                            </p>
                            <p>
                                Publishing on The Farm follows a very methodical process that focuses on a 5-step procedure called the “Barnstorming Summit.” This is process-oriented and based upon two decades of knowledge, skill, and experience. It is the core fundamental to our publishing services. We love having fun with our clients and collaborating with them through the development process to ensure that we exceed our clients' exact needs.
                            </p>
                            <p>
                                We have built an award-winning publishing process that incorporates our process, people and technologies, complemented with our sincere passion for exceeding our clients' expectations for excellence. Our client is at the center of our universe and is the prime focus of everything that we do. Our team has a customer-centric philosophy which enables Lunar Cow Publishing to deliver upon the expectations, commitments and forecasts that we develop for our clients' projects. Our clients help us drive our processes, thoughts, creativity, ingenuity, innovation, and overall satisfaction initiatives.
                            </p>
                            <p>
                                Our secret formula to publishing success lies in the hybrid relationship between print and mobile interactive technologies. We provide robust platforms that support print and mobile interactive technologies that allow us to meet the needs of readers, members, visitors, and the communities we represent at large.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default PublishingServicePage;
