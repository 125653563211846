import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/browser';
import AppRouter from './routers/AppRouter';

// Initialize Sentry for bug tracking and error handling
// TODO: Set only to be called during production deployment
// Sentry.init({ dsn: '' });

// Render the app router
ReactDOM.render(<AppRouter />, document.getElementById('app'));
