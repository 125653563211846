import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ProjectManagementProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>PROJECT MANAGEMENT</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-project-management.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-project-management.png" alt="Project Management Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Weekly client meetings are scheduled to discuss the progress of the project and to keep you informed on the action items, milestones, and overall publishing process. Basecamp project communication software is used to help manage the publishing process and to keep you informed continuously on the milestones being completed for your project. This software tracks all communications, files, and exchanges between both our teams.
                            </p>
                            <p>
                                A robust website is provided for you and your team with 24-hour access to reports, advertising sales information, agreements, artwork proofs, release forms and timelines, all designed to keep you up to date on every aspect of your project.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ProjectManagementProcessPage;
