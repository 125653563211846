import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const EmailMetricsPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>EMAIL METRICS</strong></h1>
            </div>
        </header>

        <section className="section pt-5 pb-5" style={{ backgroundColor: '#2780b9' }}>
            <div className="container">
                <div className="row gap-y align-items-center">
                    <div className="col-md-9">
                        <h4 className="mb-0 text-center text-md-left text-white fw-500 ft-gotham-book">View the Email Metrics Report in PDF format</h4>
                    </div>

                    <div className="col-md-3 text-center text-md-right">
                        <a className="btn btn-lg btn-round btn-light ft-gotham-book" href="http://www.lunarcow.com/uploads/email-metrics.pdf" target="_blank">Open PDF</a>
                    </div>
                </div>
            </div>
        </section>

        <main className="main-content">
            <section className="section text-white bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/hero.png" className="rounded" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-introduction.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <p className="lead">
                                Today's growth marketers know that it is always possible to better understand their customers. User preferences and lifetime value are not set in stone, so it's essential to possess a spirit of self-improvement around the tools and tactics that drive marketing strategy.
                            </p>
                            <p className="lead">
                                Email marketing continues to be the best digital channel for ROI, so improving email performance with key metrics is of the utmost importance. After all, how can you engage your customers if you're not asking the right questions or collecting the right data?
                            </p>
                            <p className="lead">
                                Whether you're evaluating email providers or building out your growth marketing stack, it's important to select technologies that can ensure high deliverability, create more targeted messaging and analyze campaign results in real time for sophisticated data-driven marketing.
                            </p>
                            <p className="lead">
                                This guide is for growth marketers and technologists interested in implementing high-performing email campaigns. In it, we cover the pivotal metrics used by three core groups:
                            </p>
                        </div>
                        <div className="col-md-6">
                            <ul style={{ fontSize: '2rem' }}>
                                <li>EMAIL MARKETERS</li>
                                <li>DEVOPS AND ENGINEERS</li>
                                <li>MARKETING EXECUTIVES</li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/bullseye.png" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-key-takeaways.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <p className="lead">
                                Deploying successful email campaigns is certainly a team effort. From the CMO to the Email Marketing Specialist to the programmers working behind the scenes, there are key metrics that each individual must consider.
                            </p>
                            <p className="lead">
                                To empower your organization to develop a data-driven email marketing strategy, we dive deeper into the industry benchmarks that email marketers, engineers and executives should care most about, revealing best practices for more informed decision-making.
                            </p>
                            <p>
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/key-takeaways.png" alt="..." />
                            </p>
                            <h3 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Email Marketers</h3>
                            <p>
                                Email marketers manage the metrics that are most commonly discussed: opens, clicks, unsubscribes and the like. Holistically, however, individual managers of email campaigns are concerned with increasing the level of user engagement. Sophisticated segmentation and on-the-fly experimentation are key to turning boring blasts into highly personalized messaging.
                            </p>
                            <h3 className="ft-tungsten-md" style={{ fontSize: '45px' }}>DevOps and Engineers</h3>
                            <p>
                                For every successful email marketer, there is an engineer observing campaign performance on the back end. DevOps and technical operations teams are usually responsible for measures such as traffic-shaping and bounce handling to ensure high deliverability. Deliverability requires the full participation of both the marketer who creates the emails and the engineer who helps manage the systems that send them.
                            </p>
                            <h3 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Marketing Executives</h3>
                            <p>
                                After the email marketers and engineers deploy campaigns, it's the marketing executive who must then see the forest for the trees. CMOs and other senior leaders should look to strategic metrics to determine true ROI from email. More importantly, executives must understand that the email journey is cyclical and requires optimization over time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-email-marketers.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <h1 className="text-center ft-tungsten-md" style={{ fontSize: '65px' }}>METRICS FOR ENGAGEMENT</h1>
                            <p className="lead">
                                Much of what is discussed in marketing is related to user acquisition, the strategy of gaining new members of your audience. But once an email address has been obtained, it's up to the email marketer to keep a new user engaged in order to increase their lifetime value. Depending on your business model, there are three possible goals for user engagement.
                            </p>
                        </div>
                    </div>

                    <div className="row gap-y mx-auto align-items-center">
                        <div className="col-md-3">
                            <p>1. Getting a customer to purchase multiple times or at higher price points.</p>
                        </div>
                        <div className="col-md-3">
                            <p>2. Keeping a customer interested in order to prevent churn.</p>
                        </div>
                        <div className="col-md-3">
                            <p>3. Encouraging a user to view content on a regular basis.</p>
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <p className="lead">
                                There are many metrics that email marketers can use to track user engagement. With the right growth marketing platform, these metrics can be observed in real time and drilled down by device and location, giving you a comprehensive view of who is engaged, where and when.
                            </p>
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/table-marketers.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p className="lead">
                                Although results may vary, these are the benchmarks for user engagement metrics that email marketers can expect.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-email-rates.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <div class="media">
                                <img class="mr-4" src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/open-rate.png" alt="..." />
                                <div class="media-body">
                                    <h5>Open Rate</h5>
                                    <p>
                                        Your confirmed open rate is the percentage of unique opens divided by the total number of accepted emails. Most often, an email open is measured only when the reader's email client displays HTML with images. Open rates will vary between email type (transactional emails perform better than promotional blasts) and industry (travel companies and nonprofit organizations typically have higher opens).
                                    </p>
                                </div>
                            </div>
                            <div class="media">
                                <img class="mr-4" src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/click-through-rate.png" alt="..." />
                                <div class="media-body">
                                    <h5>Click-Through-Rate (CTR)</h5>
                                    <p>
                                        An email's CTR is measured by the number of times a link is clicked from a message divided by the number of accepted emails. Unique CTR refers to the number of people who clicked, not the total times a link was clicked. It's important to analyze the CTR of an individual email, as well as emails within or across campaigns, to better determine user engagement.
                                    </p>
                                </div>
                            </div>
                            <div class="media">
                                <img class="mr-4" src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/click-to-open-rate.png" alt="..." />
                                <div class="media-body">
                                    <h5>Click-To-Open Rate (CTO)</h5>
                                    <p>
                                        This metric is calculated by the unique number of times a link is clicked from a message divided by the unique number of confirmed opens. By definition, average CTO will rank higher than CTR. However, as mentioned previously, there is no guaranteed method for determining how many subscribers physically looked at your email, so both open and click-to-open rates are estimates.
                                    </p>
                                </div>
                            </div>
                            <div class="media">
                                <img class="mr-4" src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/unsubscribe-rate.png" alt="..." />
                                <div class="media-body">
                                    <h5>Unsubscribe Rate</h5>
                                    <p>
                                        The CAN-SPAM Act mandates that users have the right to opt out of commercial messages. Your unsubscribe rate is the percentage of recipients who unsubscribe from receiving email communications in the future. Though few of us enjoy seeing unsubscribes to our emails, they do serve as a sort of "tough love" that helps improve overall list quality as well as a learning opportunity for fine-tuning content.

                                    </p>
                                    <p>
                                        These email metrics are key to understanding how to communicate with your users, but there are many more that must be analyzed behind the inbox. In the next section, we go into the details of the metrics that technical email operations teams must consider to ensure high email deliverability and performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-engineers.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Metrics for Deliverability and Performance</h2>
                            <p className="lead">
                                As we've described, metrics such as opens and clicks are fundamental aspects of measuring user engagement. These engagement-level metrics are critical measures of the health of a marketing campaign. But technical teams also need insight into message behaviors in order to optimize the performance of systems and the resources they use. The metrics they measure provide key insight into the operational health of the email infrastructure upon which marketers rely.
                            </p>

                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/table-engineers.png" alt="..." />

                            <p className="lead mx-auto">
                                Although results will depend on email infrastructure, these benchmarks are considered acceptable for industry standards.
                            </p>

                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Deliverability</h2>
                            <p className="lead">
                                We define deliverability as the proportion of emails that were successfully delivered to customers' inboxes. However, the term "deliverability" is sometimes confused with message acceptance rate, which is the share of messages actually delivered to an ISP (i.e., did not bounce) divided by how many were sent in total.
                            </p>
                            <p className="lead">
                                Assessing how many messages actually went to the inbox requires deductive reasoning and a variety of statistical approaches. You should also note that your brand reputation, technical infrastructure, and user engagement can all affect email deliverability, so make sure these contributing factors are in good standing.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-bounce-rate.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Bounce Rate</h2>
                            <p className="lead">
                                Bounces are messages that are returned as undeliverable; your bounce rate is calculated as the percentage of bounces compared to the number of total messages sent.
                            </p>
                            <p className="lead">
                                Bounces are categorized as either "hard" (permanent failures, like an invalid email address) or "soft" (temporary delays, like a full mailbox).
                            </p>
                            <p className="lead">
                                Hard bounces are the most meaningful metric, because they directly relate to the quality of your mailing list. If your hard bounce rate gets too high, ISPs will be more likely to assign you to the spam folder or even refuse message deliveries altogether.
                            </p>
                            <p className="lead">
                                As such, your bounce rate is a crucial indicator of your list quality and your overall sending health.
                            </p>

                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Latency</h2>
                            <p className="lead">
                                Message latency is defined as the time it takes for a transmitted message to be accepted by the receiving ISP. In many cases, it may take just a few seconds for messages to be delivered. However, because there are a number of external factors, including public network conditions, message latency can vary significantly from ISP to ISP.
                            </p>
                            <p className="lead">
                                In a healthy sending infrastructure, internal latency (that is, time from message creation to outbound queuing) should not exceed a few seconds.
                            </p>
                            <p className="lead">
                                Anything longer than that is a sign of overloaded or misconfigured systems and should be taken as a warning that something is not performing correctly.
                            </p>
                            <p className="lead">
                                Like the old expression "time is money," poor message latency can negatively affect a number of metrics, including open, click and conversion rates.
                            </p>
                            <p className="lead">
                                Although most business users will need only a high-level summary of technical measures like these, operations teams certainly should keep a close eye on them. They are key indicators of email infrastructure health, and any problems at this level soon could cascade into affecting key marketing and business metrics.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-strategy.png" alt="..." />
                        </div>
                    </div>
                    <div className="row gap-y align-items-center">
                        <div className="col-md-8 mr-auto ft-gotham-book">
                            <p className="lead">
                                While email marketers and engineers work in real time to deploy campaigns, it's the marketing executive's responsibility to take a step back and determine whether a campaign is achieving the objectives of the business.
                            </p>
                            <p className="lead">
                                If user engagement and deliverability remain high, then a CMO can analyze whether the marketing ROI is following suit. Important strategic metrics for executives to track are conversions, average order value and customer lifetime value.
                            </p>
                        </div>
                        <div className="col-md-2 ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/strategy.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Conversion Rate</h2>
                            <p className="lead">
                                How you define conversions will depend on the desired actions you want users to take, whether it's viewing a tutorial or downloading your mobile app. Your conversion rate is calculated by the number of people who completed the required action divided by the total number of users. For most businesses, a purchase is the ultimate conversion. Attribution models, such as first touch, last touch, or time decay, enable marketers to determine which behavioral events will quickly convert a user and lead to more purchases.
                            </p>

                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Average Order Value</h2>
                            <p className="lead">
                                While increasing the total number of purchases is important to B2C companies, it's also crucial to improve the average order value (AOV). This metric is measured by dividing the total revenue by the number of orders taken. Common methods of increasing AOV include offering cross-selling and up-selling opportunities, volume discounts and product bundles. Establishing a loyalty program and a free shipping threshold can also boost AOV and increase profits.
                            </p>

                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>Customer Lifetime Value</h2>
                            <p className="lead">
                                A customer's lifetime value is based on a prediction of the net profit attributed to their entire future relationship with the business. There are many methods of calculating this metric, but overall, they take a company's margin, retention and discount rates into account. By calculating a customer's monetary value, marketing executives can better determine the cost of customer acquisition and allocate resources accordingly. Just remember that this metric is highly dynamic and must be re-evaluated regularly to ensure its accuracy.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-marketing-executives.png" alt="..." />
                        </div>
                    </div>

                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mr-auto ft-gotham-book">
                            <p className="lead">
                                Identify what "growth" means in your business and at your stage of development. Be specific, using strategic metrics like the ones mentioned above.
                            </p>
                            <p className="lead">
                                Isolate the individual levers that can drive this growth, which may exist several layers deep.
                            </p>
                            <p className="lead">
                                Systematically measure and test each lever, with the strategic metrics as the optimization goal.
                            </p>

                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/marketing-execs.png" alt="..." />

                            <p className="lead">
                                Executives should note that optimization is naturally a cyclical process, meaning it's important to iterate again and again with each email marketing campaign. Check in with the marketing and engineering teams frequently to better understand when your goals and metrics need to be re-evaluated.
                            </p>
                            <p className="lead">
                                The CMO must ensure that this process is accomplished quickly and cost-effectively for maximum impact. Utilizing marketing automation technology is the smartest and most scalable way for executives to ramp up their growth marketing efforts.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-5 mr-auto ft-gotham-book">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/header-conclusion.png" alt="..." />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/email-metrics/footer.png" className="rounded" alt="..." />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center ft-gotham-book">
                            <h2 className="ft-tungsten-md mt-3" style={{ color: '#000', fontSize: '30px' }}>The CMO must ensure that this process is accomplished quickly and cost-effectively for maximum impact. Utilizing marketing automation technology is the smartest and most scalable way for executives to ramp up their growth marketing efforts.</h2>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default EmailMetricsPage;
