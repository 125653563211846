import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const DeliveryProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>DELIVERY</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-shipping.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-shipping.png" alt="Delivery Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Printing and delivery are the final, critical stages of this campaign. Color-accurate proofs will be provided to you for final review. Once approved, the publication will be printed and delivered.
                            </p>
                            <p>
                                The delivery of your business directories will be shipped to your zip code. The shipping truck will have a lift gate and pallet jack. Inside delivery is not included in this proposal. We will work with you so that we can determine the exact address for delivery, hours of operation, contact person, cell phone information and any special delivery instructions there might be for the project.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default DeliveryProcessPage;
