import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const WebDevelopmentServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/web-development-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>WEB DEVELOPMENT</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                At Lunar Cow Publishing, web development is a holistic process. Our websites don’t only look great, they also perform at the highest level. From the start, we consider your content strategy, information architecture, user-pathways and how to market your site to your audience.
                            </p>
                            <p>
                                Every professional website design project starts with a discovery and strategy phase where the web design team – consisting of web strategists, account managers, web designers, and technical managers – interview the client stakeholders on their value differentiation, competitive landscape, and goals for the project. The outcome of this phase is a documented timeline, detailed project plan and a web strategy.
                            </p>
                            <p>
                                We create custom website designs with strategic insights to generate greater brand engagement, higher conversions, and measurable results. From B2B to B2C websites, we engineer a tailored, responsive design built to perform.
                            </p>
                            <p>
                                Lunar Cow Publishing uses the latest web development platforms to ensure that your website produces the expected outcomes. A mobile-first focus allows your websites to be responsive while catering to the needs of smart-device users. Both front-end UI and back-end CMS functionality are critical to the overall scope, execution, management, and usability of our website development support services.
                            </p>
                            <p>
                                We employ a collaborative development philosophy that allows our development team to work closely with our clients to ensure that your requirements, expectations, and goals are facilitated exactly to the scope of the campaign.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default WebDevelopmentServicePage;
