import React from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { auth, firestore } from '../../firebase/firebase.utils';
import ls from 'local-storage';

class LoginPage extends React.Component {
    constructor (props) {
        super(props);

        // Store the state for the login page
        this.state = {
            email: '',
            error: ''
        };
    }

    componentDidMount () {
        // TODO: Check if the user is already authenticated, and
        // redirect them to the appropriate dashboard
    }

    // Handle resetting the users password
    recover = e => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // TODO
    };

    render() {
        // Extract the email and any errors from the state
        const { email, error } = this.state;

        return (
            <div className="layout-centered bg-img" style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/images/auth/farm.png)' }}>
                <main className="main-content" style={{ height: '100vh' }}>
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <h5 className="mb-7">
                            <Link to="/">
                                <img
                                    className="logo-dark mr-3"
                                    src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/logo-dark.png"
                                    alt="Lunar Cow Publishing"
                                    style={{ height: '30px' }}
                                />
                            </Link>

                            Recover Password
                        </h5>

                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    name="username"
                                    placeholder="Email"
                                    value={email || ''}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                            </div>

                            <div className="form-group flexbox py-3">
                                <NavLink
                                    className="text-muted small-2 ml-auto"
                                    to="/auth/login">
                                    Remembered Your Password? Login
                                </NavLink>
                            </div>

                            <div className="form-group">
                                <button
                                    className="btn btn-block btn-primary"
                                    onClick={this.recover}>
                                    Reset
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        );
    }
}

export default LoginPage;
