import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const AdvertisingSalesServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/advertising-sales-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>ADVERTISING SALES</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                Advertising sales is the core fundamental in allowing your publications to be self-sustaining as well as provide revenue.
                            </p>
                            <p>
                                Here at Lunar Cow Publishing, we utilize a permission-based sales philosophy which allows our sales team to produce results up to 30 percent higher than traditional door-to-door or face-to-face advertising sales. We incorporate the six channels of effective sales promotions and communications, allowing our team of dedicated advertising sales specialists to produce higher-than-expected results.
                            </p>
                            <p>
                                Advertising sales project timelines with detailed milestones will be defined shortly after our “Barnstorming Summit” planning session. Special care will be given to planning advertising sales timeframes that include selling, art collection and payment dates to ensure that your publication is produced on time.
                            </p>
                            <p>
                                Our design team will produce a rate card which will be the primary marketing communication piece used to detail the advertising opportunities for your publication. We will prepare a comprehensive media kit which includes direct mail, e-mail, social media, newsletter, website, and post card examples for each of the six channels used to create response from prospective advertisers. Our team will prepare all of the core fundamental elements of your advertising sales program to ensure success from our advertising sales efforts.
                            </p>
                            <p>
                                We will work to acquire your marketing lists broken down by renewals, win backs and prospects in an agreed-upon format. Upon receipt of your marketing lists, they will be placed into the Lunar Cow Publishing CRM (Customer Relationship Management) system. You will be trained on the CRM system and provided with the proper credentials for easy access to data and reports.
                            </p>
                            <p>
                                Lunar Cow Publishing will invoice each advertiser on behalf of your organization. Our staff will also perform collections calls to any advertiser which has not paid in the terms allotted. The payment for the invoice can be sent directly to your organization or to Lunar Cow Publishing.
                            </p>
                            <p>
                                The advertising sales portion of your publishing project will take eight weeks to complete. We will set up weekly and monthly meetings to discuss our success with the advertising sales program as well as provide project management updates throughout the entirety of the project.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default AdvertisingSalesServicePage;
