import React from 'react';
import uuid from 'uuid';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

class FarmhandsPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            farmhands: [{
                id: 1,
                name: 'Ben Harris',
                title: 'President',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/ben-harris.jpg',
                desc: 'I never want to experience a day where I am not surrounded by family, friends, and loved ones. Life is too short to go through a day without enjoying the important things. Taking time to relax, enjoying the opportunities that each day provides and giving thanks are really important to me.',
                open: false
            }, {
                id: 2,
                name: 'Mike Hatter',
                title: 'Senior Full Stack Engineer',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/mike-hatter.jpg',
                desc: 'A dependable and detail-oriented software engineer with a specialty in product development. Able to analyze user requirements to develop, test, and implement software solutions.',
                open: false
            }, {
                id: 3,
                name: 'Jack McFadden',
                title: 'Project Manager',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/jack-mcfadden-255x255.png',
                desc: 'Winners do actually sweat the small stuff. They know that getting the details right is the difference between success and failure.',
                open: false
            }, {
                id: 4,
                name: 'Dave Potokar',
                title: 'Senior Designer',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/dave-potokar.jpg',
                desc: 'Creativity is intelligence having fun. Good design is obvious, but great design is transparent.',
                open: false
            }, {
                id: 5,
                name: 'Matt Brady',
                title: 'Designer',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/matt-brady.jpg',
                desc: 'Saving the world from bad design, one graphic at a time.',
                open: false
            }, {
                id: 6,
                name: 'Ed Asper',
                title: 'Ad Sales Manager',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/ed-asper.jpg',
                desc: 'I am results-oriented, constantly checking in with the goal to determine how close or how far away we are and what it will take to make it happen. I find this pressure inspiring and a great motivator for the rest of the team.',
                open: false
            }, {
                id: 7,
                name: 'Adam Moser',
                title: 'Lead Generator',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/adam-moser.jpg',
                desc: 'I\'m very passionate about volunteering my time with my family\'s non-profit organization helping families and individuals with special needs. It’s not about the length of life, but the depth – Ralph Waldo Emerson.',
                open: false
            }, {
                id: 8,
                name: 'Christian Hinton',
                title: 'Ad Sales Representative',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/christian-hinton.jpg',
                desc: 'As assistant sales manager, my goal is the maintaining of a great relationship with our clients and the ad sales team. I believe positive attitude and fun demeanor goes a long way in ensuring positive rapport with those around me. ',
                open: false
            }, {
                id: 9,
                name: 'Abigail Riley',
                title: 'Ad Sales Representative',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/abigail-riley.jpg',
                desc: 'I strive to give people the best quality service available.',
                open: false
            }, {
                id: 10,
                name: 'Diana Riley',
                title: 'Ad Sales Representative',
                image: 'https://lunarcow.nyc3.cdn.digitaloceanspaces.com/employees/images/diana-riley.jpg',
                desc: 'Every day on the Farm brings me joy as I look forward to providing our clients excellent customer service through my positive attitude and outgoing personality.',
                open: false
            }],
        };
    }

    toggleDetails = (e, id) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Get the farmhands from the state
        const { farmhands } = this.state;

        // Grab the array from the state so we don't mutate directly
        let updatedFarmhands = farmhands;

        // Get the index of the farmhand we want to turn on
        const farmhandIndex = farmhands.findIndex(f => f.id == id);
        const currentlyOpen = farmhands[farmhandIndex].open;

        // Check if the current farmhand is open
        if (currentlyOpen) {
            updatedFarmhands[farmhandIndex].open = false;
        } else {
            // Turn off everyone's open-flag
            updatedFarmhands.forEach(f => f.open = false);
            updatedFarmhands[farmhandIndex].open = true;
        }

        // Update the farmhands in the state
        this.setState({ farmhands: updatedFarmhands });
    };

    render () {
        // Get the farmhands from the state
        const { farmhands } = this.state;

        // Create an array to store rows of 3 for the employees
        let employees = [];

        for (let i = 0, j = farmhands.length; i < j; i += 3) {
            employees.push(farmhands.slice(i, i + 3));
        }

        let rows = [];

        for (let i = 0; i < employees.length; i++) {
            rows[i] = [];

            for(let j = 0; j < employees[i].length; j++) {
                let farmhand = employees[i][j];

                rows[i].push(
                    <div className="col-md-3" style={{ float: 'none', margin: '0 auto' }} key={uuid()}>
                        <div className="card d-block farmhand-img">
                            <a href="#" onClick={e => this.toggleDetails(e, farmhand.id)}>
                                <img className="card-img-top" src={farmhand.image} alt={`${farmhand.name}'s thumbnail`} />
                                <span className="blue-overlay" style={{ display: `${farmhand.open ? 'block': ''}`}}>
                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '35%' }}>
                                        <i className={`fa fa-3x ${farmhand.open ? 'fa-times' : 'fa-plus'}`} />
                                    </span>
                                </span>
                            </a>

                            <div className={`card-body ${farmhand.open ? 'farmhand-card-active' : 'bg-white'}`} style={{ marginTop: '1rem', border: '1px solid #bbb' }}>
                                <h5 className="card-title ft-gotham-bold" style={{ fontSize: '20px' }}>{farmhand.name}</h5>
                                <p className="ft-gotham-book" style={{ fontSize: '15px' }}>{farmhand.title}</p>
                            </div>
                        </div>
                    </div>
                );

                if (farmhand.open) {
                    rows[i].push(
                        <div className="col-md-3" key={uuid()}>
                            <div className="card d-block">
                                <div className="card-body bg-white text-left" style={{ border: '1px solid #bbb' }}>
                                    <p>{farmhand.desc}</p>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }

        return (
            <div>
                <HeaderBar />

                <header
                    className="header text-white bg-img"
                    style={{
                        backgroundColor: '#212652',
                        backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/farmhands.png)'
                    }}
                >
                    <div className="container text-center">
                        <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>FARMHANDS</strong></h1>
                    </div>
                </header>

                <main className="main-content">
                    <section className="section bg-white">
                        <div className="container">
                            {employees.map((row, i) => (
                                <div className="row gap-y" key={uuid()}>
                                    {rows[i]}
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="section bg-white" style={{ paddingTop: '0px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 mx-auto ft-gotham-book">
                                    <p>Our creative-minded business professionals have backgrounds in graphic design, technology, marketing, and business. Each team member blends an energetic passion for success with proven market research, strategic planning, and best practices program development to achieve brand communications that get noticed and deliver measurable results.</p>
                                    <p>From Founder and Project Manager to Senior Designer, Advertising Sales Manager, and the rest of our Farmhands, we all work together from early morning until the cows come home at night to provide complete client satisfaction. Delighting our clients is the common bond among our team that unites us and differentiates us from all others.</p>
                                    <p>Our organization has developed a very specific set of skills, knowledge, expertise and passion in the destination marketing industry. Lunar Cow Publishing supports chambers of commerce and convention and visitor bureaus as our primary market focus.</p>
                                    <p>We provide print publishing, digital publishing and mobile interactive technologies designed to help our clients produce award-winning products that bring enhanced value to the members, visitors, and communities that they serve.</p>
                                    <p>From our dedicated design staff that specializes in producing publications and community maps, to our advertising sales specialists who increase revenue for our clients, to our national network of certified printers that help us to reduce the costs of our clients’ projects, we have a collective passion to drive the highest level of satisfaction for our clients. This specialization helps differentiate us and creates a unique competitive advantage that our clients leverage. The Lunar Cow Publishing team helps to differentiate our company, providing unparalleled service and driving complete client satisfaction.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default FarmhandsPage;
