import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
    <footer className="footer bg-gray py-9">
        <div className="container">
            <div className="row gap-y">
                <div className="col-md-6 col-xl-4">
                    <p>
                        <a href="#">
                            <img
                                src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/svg/logo-20.svg"
                                style={{ height: '35px' }}
                                alt="Lunar Cow Publishing"
                            />
                        </a>
                    </p>
                    <p>
                        344 W Turkeyfoot Lake Rd, Suite B<br />
                        Akron, OH 44319<br />
                        <strong>info@lunarcow.com</strong>
                    </p>
                </div>

                <div className="col-6 col-md-3 col-xl-2">
                    <h6 className="mb-4 mt-1"><strong>Company</strong></h6>
                    <div className="nav flex-column">
                        <a href="/#about" className="nav-link">About Us</a>
                        <Link to="/farmhands" className="nav-link">Farmhands</Link>
                        <a href="/#contact" className="nav-link">Contact</a>
                    </div>
                </div>

                <div className="col-6 col-md-3 col-xl-2">
                    <h6 className="mb-4 mt-1"><strong>Services</strong></h6>
                    <div className="nav flex-column">
                        <Link to="/services/publishing" className="nav-link">Publishing</Link>
                        <Link to="/services/advertising-sales" className="nav-link">Advertising Sales</Link>
                        <Link to="/services/printing" className="nav-link">Commercial Printing</Link>
                    </div>
                </div>

                <div className="col-6 col-md-6 col-xl-2">
                    <h6 className="mb-4 mt-1"><strong>Products</strong></h6>
                    <div className="nav flex-column">
                        <Link to="/services/creative-design" className="nav-link">Creative Design</Link>
                        <Link to="/services/imap" className="nav-link">Community Maps</Link>
                        <Link to="/services/digital-publishing" className="nav-link">Mobile Technologies</Link>
                    </div>
                </div>
                <div className="col-6 col-md-6 col-xl-2 text-center">
                    <p>
                        <Link to="/schedule-demo" className="btn btn-block btn-round btn-secondary">Schedule Demo</Link>
                        <Link to="/get-quote" className="btn btn-block btn-round btn-secondary">Get a Quote</Link>
                    </p>
                    <div className="social social-bordered">
                        <a className="social-facebook" href="http://www.facebook.com/lunarcow" target="_blank"><i className="fa fa-facebook" /></a>
                        <a className="social-twitter" href="http://www.twitter.com/lunarcow" target="_blank"><i className="fa fa-twitter" /></a>
                        <a className="social-youtube" href="https://www.youtube.com/channel/UCfrh92fpEsIGjJkI-mwJgaQ" target="_blank"><i className="fa fa-youtube" /></a>
                        <a className="social-instagram" href="https://www.instagram.com/lunarcowpublishing" target="_blank"><i className="fa fa-instagram" /></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
