import React from 'react';
import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const TosPage = () => (
  <div>
    <HeaderBar />

    <header
      className="header text-white"
      style={{ backgroundColor: '#212652' }}
    >
      <div className="container text-center">
        <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}>
          <strong>TERMS OF SERVICE</strong>
        </h1>
      </div>
    </header>

    <main className="main-content">
      <section className="section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <p>
                These Lunar Cow Publishing Proposal Terms and Conditions ("Terms
                and Conditions") are attached to the Lunar Cow Publishing
                Proposal ("Proposal") that will act as the agreement between
                Lunar Cow Publishing, Inc. ("Lunar Cow") and you ("Client") for
                purposes of the transactions referenced in the Proposal.
              </p>
              <h4>1. SPECIFICATIONS.</h4>
              <p>
                Any products and/or services to be supplied by Lunar Cow under
                the Proposal will be provided in accordance with the
                specifications set forth in the Proposal, except as otherwise
                stated below. Any dates or schedules specified in the Proposal
                for the delivery of any products or services by Lunar Cow are
                only estimates and shall not be binding upon Lunar Cow. Lunar
                Cow shall not incur any liability, either directly or
                indirectly, nor shall any engagement be canceled as a result of
                any delays in meeting such dates or schedules.
              </p>
              <h4>2. PRICING.</h4>
              <p>
                The products and/or services set forth in the Proposal will be
                provided by Lunar Cow to Client at the prices in the Proposal,
                except as otherwise stated herein. For any multi-guide
                Proposals, Lunar Cow reserves the right to increase the price
                for any subsequent guide price by an amount equal to the
                percentage change in the Consumer Price Index ("CPI") statistics
                published by the United States Bureau of Labor. The change shall
                be determined by comparison of the figure for the previous
                January 1, with that of January 1 of the current year. In no
                event shall this calculation cause a reduction in the guide
                pricing below that payable during the preceding period.
              </p>
              <p style={{ textDecoration: 'underline' }}>
                Lunar Cow also reserves the right to impose a surcharge on any
                third-party services provided in the Proposal wherein Lunar
                Cow's vendor, supplier, or provider (e.g. publishers,
                photographers, talent), as the case may be, increase(s) the
                charges to Lunar Cow, beyond the charges in place at the time of
                the initial Proposal. Lunar Cow will make all reasonable effort
                to advise Client of any surcharge imposed upon it by its
                third-party providers upon notice of the same.
              </p>
              <p>
                Any products or services provided by Lunar Cow not listed in the
                Proposal shall be billed at Lunar Cow's customary charges or
                rates. Further, expedited or rush service by Lunar Cow will be
                subject to additional charges as established by Lunar Cow from
                time to time. All prices will be exclusive of expenses incurred
                by Lunar Cow in providing the products and/or services under the
                Proposal. Client shall be responsible for all out-of-pocket
                expenses incurred by Lunar Cow in providing the products and/or
                services, including, but not limited to, copy charges, travel
                costs, model and other talent fees, equipment use and rental
                fees, disk charges, shipping charges and other related costs and
                expenses.
              </p>
              <h4>3. TAXES.</h4>
              <p>
                Client is solely responsible for payment of any taxes (including
                sales or use taxes, excise taxes, intangible and property taxes
                and similar taxes) resulting from products and services provided
                under the Proposal, excluding, however, any taxes payable by
                Lunar Cow due to income earned by it or withholding taxes to its
                employees.
              </p>
              <h4>4. DESIGN REVIEW.</h4>
              <p>
                With respect to any design work performed by Lunar Cow under the
                Proposal, Lunar Cow will present Client an initial concept
                design. Upon presentment of this concept design, Client will
                have the opportunity to work with Lunar Cow in its redesign. The
                prices set forth in the Proposal are based upon a maximum of two
                (2) requests for changes to the initial concept design. Any
                changes to the initial concept design requested by Client in
                excess of such amount or any changes requested after acceptance
                of the concept design will be billed by Lunar Cow at its
                customary rates for such services.
              </p>
              <h4>5. REVIEW OF DELIVERABLES.</h4>
              <p>
                Upon the completion of any deliverable in the Proposal, Client
                will have three (3) business days to review or test the
                deliverable and notify Lunar Cow of any problems with such
                deliverable. If Client does not timely notify Lunar Cow in
                writing of any problem, such deliverable will be deemed to be
                accepted by Client. In the event of any delay caused by Client's
                failure to timely make any delivery set forth in the Proposal or
                respond to Lunar Cow in a timely manner, Lunar Cow will not be
                responsible to meet any due dates set forth in the Proposal,
                which shall be extended in Lunar Cow's discretion.
              </p>
              <h4>6. CHANGES.</h4>
              <p>
                In the event Client requests any changes or modifications to the
                products or services provided under the Proposal, Client will be
                responsible for all charges reasonably incurred by Lunar Cow
                with respect to such changes. Further, any times scheduled in
                the Proposal for the delivery may be extended by Lunar Cow upon
                any such requested changes or modifications.
              </p>
              <h4>7. CLIENT'S REPRESENTATIONS.</h4>
              <p>
                Client represents and warrants to Lunar Cow that: a. No content
                or information or materials delivered by Client to Lunar Cow
                will: (i) be libelous, defamatory, obscene, profane,
                threatening, harassing or offensive or otherwise violate the
                privacy or publicity rights of any third party; (ii) constitute
                a fraud or other unlawful business practice, (iii) infringe on
                any copyright, patent, trade secret or other proprietary right
                held by any third party, or (iv) violate any federal, state or
                local law, regulation or orders. b. Client has secured all
                necessary licenses and clearances for use of the content,
                including all rights to copy, distribute, modify, perform and
                display the content and to create derivative works of the
                content. Unless otherwise agreed by Lunar Cow in the Proposal,
                all content will be supplied by Client. c. Lunar Cow may refrain
                from starting any work under the Proposal until such time as
                Client has delivered to Lunar Cow all required content,
                including, but not limited to, copy, image files, logos and
                reference materials. Any delays in providing such content by
                Client will extend the time period for the delivery. Lunar Cow
                has no obligation to review any content, information or other
                material supplied by Client and does not assume any liability
                therefore. d. Lunar Cow shall not be responsible for any delays
                or inability of third parties used by Lunar Cow to provide the
                products and/or services under the Proposal. Any price estimates
                in the Proposal may be based on a quote from a third party.
                Lunar Cow shall not be bound by any price quote in the Proposal
                if any such third party fails to provide the products or
                services as agreed.
              </p>
              <h4>8. CLIENT RESPONSIBILITIES.</h4>
              <p>
                Client shall reasonably respond (no less than 48 hours later) to
                any and all communication from Lunar Cow. Client shall promptly
                advise Lunar Cow of any change in its team members and contact
                information (e.g. name, address, phone number, e-mail address).
                Failure of Client to timely communicate with Lunar Cow may cause
                Lunar Cow to extend dates for delivery or completion of products
                and/or services. If Client is non-responsive to communication
                from Lunar Cow (including but not limited to telephone, e-mail,
                texts, written correspondence, portal communication) for a
                period of six (6) months or longer, then Client shall have been
                deemed to forfeit its rights under the Proposal, and be deemed
                in breach of the Proposal, and Lunar Cow shall be entitled to
                retain any and all monies, and to seek breach of contract
                damages under Section 11 below.
              </p>
              <h4>9. NON-INFRINGEMENT.</h4>
              <p>
                Lunar Cow represents and warrants to Client that the graphics,
                scripts, text, source code, software and other materials
                supplied or prepared by Lunar Cow under the Proposal will not
                infringe on any trademark, copyright, patent or other
                proprietary right held by any third party.
              </p>
              <h4>10. TERMINATION.</h4>
              <p>
                Lunar Cow shall have the right to terminate the agreement under
                the Proposal and all work to be performed thereunder (i) in the
                event of Client's breach of any provision hereof, or (ii) in the
                event a petition seeking composition of creditors, the
                protection afforded by the United States Bankruptcy Code or
                benefit of other laws affecting the rights of creditors
                generally is filed by or against Client. Upon termination by
                Lunar Cow, any and all work to be performed by Lunar Cow under
                the Proposal shall immediately terminate; whereupon Lunar Cow
                will have no responsibility to refund any payments made to it
                prior to such date of termination. Lunar Cow Publishing will not
                refund any monies of any kind, for any reason. Further, Lunar
                Cow shall have the right, at its option, to enforce the
                cancellation fee imposed under Section 11 below.
              </p>
              <h4>11. CANCELLATION/CANCELLATION FEE.</h4>
              <p>
                In the event Client cancels the engagement contemplated under
                the Proposal for any reason, including events listed in Section
                20 of these Terms and Conditions set forth below, or fails to
                deliver any items set forth in the Proposal or these Terms and
                Conditions after written request by Lunar Cow, Client shall pay
                to Lunar Cow, a cancellation fee, in lieu of other damages,
                according to the following schedule based upon Lunar Cow's
                reasonable expectation of contract damages. Client shall pay a
                cancellation fee, depending upon the date of cancellation, equal
                to the following schedule: (a) cancellation after acceptance of
                the Proposal, up through and during Milestone 1 (or equivalent
                period) - 50% of total estimated design, advertising sales, go
                guide, printing and delivery charges as contained contained in
                the Proposal ("Fees"); (b) cancellation during Milestone 2 (or
                equivalent period) - 75% of total estimated Fees; and (c)
                cancellation during Milestone 3 (or equivalent period) - 100% of
                total estimated Fees. Lunar Cow will not refund any monies of
                any kind, for any reason. In the event that the Proposal
                includes a multi-guide publication commitment, Client shall pay
                a cancellation fee for each guide publication remaining under
                the Proposal. If the Client cancels after the completion of a
                guide, but before the start of the next guide, the Client, shall
                be responsible for a cancellation fee of no less than 50% of the
                Fees anticipated for each future guide publication. (e.g. If
                Client cancels a 3-guide engagement after completion of the
                first guide, but before the start of the second or third guide,
                the Client would be responsible for a cancellation fee of 50% of
                the Fees for the second guide, and 50% of the Fees for the third
                guide). This cancellation fee cannot be waived, modified, or
                cancelled by any employee or agent of Lunar Cow.
              </p>
              <h4>12. OWNERSHIP.</h4>
              <p>
                Any content or materials supplied by Client shall remain the
                property of Client and Lunar Cow. Any image, graphics, coding,
                scripts, text, source code, software or other materials supplied
                or prepared by Lunar Cow, and any derivative works there from,
                shall remain the property of Lunar Cow; provided Lunar Cow shall
                grant to Client the limited right to use such materials, on a
                non-transferable basis, on its printed marketing material, Web
                site, or as otherwise contemplated in the Proposal.
                Notwithstanding any other provision of the Proposal or these
                Terms and Conditions, Client shall not be permitted to modify,
                reverse engineer, disassemble or decompile any software or
                source code supplied by Lunar Cow for any marketing material or
                Web site developed by Lunar Cow.
              </p>
              <h4>13. RIGHT TO DISPLAY.</h4>
              <p>
                Notwithstanding any other provision hereof, Lunar Cow will have
                the right to publicly display any Web site, including any
                components or content therein, and any design prepared or
                developed by Lunar Cow under the Proposal to any third party,
                including, but not limited to, to potential Clients in person,
                by advertisements or trade shows, or through contest
                submissions.
              </p>
              <h4>14. INDEMNIFICATION.</h4>
              <p>
                Client shall indemnify and hold Lunar Cow, and its directors,
                officers, employees and agents, harmless from and against any
                claims, losses, damages, liabilities, costs or expenses of any
                nature (including reasonable attorney's fees) suffered or
                incurred by any of them to the extent that such are caused by
                (i) a breach of the Proposal or these Terms and Conditions by
                Client, or (ii) any content or materials supplied by Client.
              </p>
              <h4>15. LIMITATION OF LIABILITIES.</h4>
              <p>
                LUNAR COW SHALL NOT BE LIABLE TO CLIENT FOR ANY INCIDENTAL,
                CONSEQUENTIAL, OR SPECIAL DAMAGES OF ANY KIND OR NATURE,
                INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOSS OF DATA,
                WHETHER ARISING OUT OF LUNAR COW'S BREACH OF CONTRACT OR
                WARRANTY, NEGLIGENCE, OR OTHERWISE (EVEN IF LUNAR COW HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE). CLIENT'S
                SOLE REMEDY UNDER THE AGREEMENT FOR A BREACH BY LUNAR COW SHALL
                BE THE PERFORMANCE OF THE SAME OR REPLACEMENT SERVICES AND/OR
                THE DELIVERY OF REPLACEMENT PRODUCTS BY LUNAR COW.
              </p>
              <h4>16. DISCLAIMER OF WARRANTIES.</h4>
              <p>
                EXCEPT AS OTHERWISE SPECIFICALLY PROVIDED HEREIN, THE PRODUCTS
                AND SERVICES PROVDIED BY LUNAR COW ARE PROVIDED "AS IS" WITHOUT
                WARRANTY OF ANY KIND. LUNAR COW EXPRESSLY DISCLAIMS ANY AND ALL
                REPRESENTATIONS AND WARRANTIES WITH RESPECT THERETO, WHETHER
                EXPRESS OR IMPLIED, ARISING BY LAW, CUSTOM, USAGE OR OTHERWISE,
                INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
              </p>
              <h4>17. GOVERNING LAW; VENUE.</h4>
              <p>
                This Agreement shall be governed by the laws of the State of
                Ohio, without reference to any conflict of laws principles, and
                all claims, actions and other proceedings relating to the
                subject matter hereof shall be brought and heard exclusively in
                the courts of Summit County, Ohio. Client hereby consents to
                jurisdiction and venue of such courts and waives any right to
                object to such jurisdiction or venue.
              </p>
              <h4>18. ATTORNEY FEES.</h4>
              <p>
                If Lunar Cow is required to resort to legal action for the
                redress of a breach hereof by Client, Lunar Cow, if successful,
                shall be entitled to an award of all of its reasonable attorney
                fees and costs.
              </p>
              <h4>19. ASSIGNMENT.</h4>
              <p>
                This Agreement shall inure to the benefit of, and be binding
                upon, the parties and their respective successors and assigns.
                Client may not assign this Agreement or any of its rights or
                duties hereunder (including the sale or transfer of majority
                control of the ownership of Client) without the prior written
                consent of Lunar Cow. Nothing in the Proposal or these Terms and
                Conditions shall be construed as creating any act or beneficial
                right in or on behalf of any third party.
              </p>
              <h4>20. FORCE MAJEURE.</h4>
              <p>
                "Force Majeure Event" means the occurrence of, but not limited
                to, the action or inaction of any governmental, civil or
                military authority; a strike, lockout or other labor dispute; a
                fire, flood, war, riot, theft, earthquake, pandemic, epidemic,
                or other natural disaster; or acts of terrorism or other civil
                disturbance, which in each case directly causes Lunar Cow to be
                unable to comply or performance is made impracticable without
                its fault by the occurrence of the event, Lunar Cow shall not be
                in breach of its obligations or liable to Client for any losses
                or damages arising out of such non-performance.
              </p>
              <h4>21. CONFIDENTIALITY.</h4>
              <p>
                Client shall treat all confidential and proprietary information
                and materials of Lunar Cow strictly confidential and shall not
                disclose such information or materials to any third party.
              </p>
              <h4>22. MISCELLANEOUS.</h4>
              <p>
                No provision hereof shall be deemed waived, amended or modified
                by either party, unless it is in writing and signed by the party
                against whom it is sought to be enforced. The failure of a party
                to enforce any provision hereof shall in no way be construed to
                be a waiver of such provision, nor in any way affect the ability
                of a party to enforce the same or other provisions thereafter.
                The Proposal and these Terms and Conditions represent the
                complete agreement of the parties with respect to the subject
                matter hereof and supersedes any prior or contemporaneous
                agreements, written or oral, between the parties. Lunar Cow
                shall be deemed an independent contractor of Client and neither
                party shall be, nor hold itself out as being, an employee or
                agent of the other party. Any notice to be given hereunder shall
                be given in writing and delivered personally, by overnight
                courier, by U.S. certified mail, postage prepaid, written
                receipt requested, or by electronic transmittal with
                confirmation.
              </p>
              <h4>23. INVOICING/PAYMENT.</h4>
              <p>
                All outstanding client invoices must be paid in full before the
                printing of any publication will occur. Lunar Cow will bill the
                Client in two invoice cycles. All invoice terms are net thirty
                (30) days. The first invoice will be sent once advertising sales
                have reached or exceeded fifty percent (50%) of the amount
                stated in the Proposal. The second invoice will be sent at the
                beginning of Milestone 4, and must be paid in full prior to
                publication. Client shall pay interest of one and one-half
                percent (1½%) per month on all amounts due but not paid within
                thirty (30) days of the invoice date. Client shall collect the
                advertising fees from its members and shall hold the same for
                the benefit of Lunar Cow. In no event shall Client refund any
                advertising money to its members without the written consent of
                Lunar Cow. All fees collected by the Client shall be remitted to
                Lunar Cow to pay any and all outstanding invoices.
              </p>
              <h4>24. COMPLETION DATE.</h4>
              <p>
                The time period between a multi-guide agreement shall not extend
                beyond twenty-four (24) months from the delivery of the last
                multi-guide agreement. If a Client does not complete all the
                multi-guides, the Client shall be in breach of the agreement.
              </p>
              <h4>25. MEMBERSHIP COMMITMENT.</h4>
              <p>
                If required, Lunar Cow will become a member of Client's
                organization. Lunar Cow's membership commitment will only be
                during periods of active production. Lunar Cow will not be
                required to maintain membership during non-production years.
                Further, Lunar Cow will only pay membership dues once
                advertising sales produce the propriate amount of revenue to pay
                for the membership fee.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>

    <PreFooter />
    <Footer />
  </div>
);

export default TosPage;
