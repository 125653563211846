import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const BarnstormingProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>BARNSTORMING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-barnstorming.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-barnstorming.png" alt="Barnstorming Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Every project begins with our Barnstorming Kickoff, which is a fun and thought-provoking collaborative session that connects your team with ours and shapes the foundation for the mission.
                            </p>
                            <p>
                                Our five-step mission schedule gives you complete understanding of the development procedures, performance results and timelines, and it ensures your complete satisfaction throughout the process.
                            </p>
                            <p>
                                Our one-to-one project management allows your team and ours to enjoy streamlined communication that keeps everyone on the same page. We have built our systems to have an intense focus on communication and client engagement. The greater our engagement, the higher level of project performance we can achieve.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default BarnstormingProcessPage;
