import React from 'react';

import { Link } from 'react-router-dom';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';
import FlipMove from 'react-flip-move';
import uuid from 'uuid';

import { firestore } from '../../firebase/firebase.utils';

class PortfolioPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            items: [],
            /*
            portfolioItems: [{
                id: '11',
                name: '',
                category: 'branding',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/thumbnail_wbf.jpg'
            }, {
                id: '22',
                name: '',
                category: 'imaps',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/legendsofthearenaicon.jpg'
            }, {
                id: '33',
                name: '',
                category: 'branding',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/th_bb.jpg'
            }, {
                id: '44',
                name: '',
                category: 'maps',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/th_cscvb.gif'
            }, {
                id: '55',
                name: '',
                category: 'maps',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/sixflagsmardigrasicon.jpg'
            }, {
                id: '66',
                name: '',
                category: 'maps',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/sfstuffacoastericon.jpg'
            }, {
                id: '77',
                name: '',
                category: 'promotions',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/sfgolftournamenticon.jpg'
            }, {
                id: '88',
                name: '',
                category: 'publications',
                thumb: 'http://www.lunarcow.com/uploads/portfolios/thumbnail_photo/sffirstaidicon.jpg'
            }],
            */
            filteredItems: [],
            filtered: false
        };
    }

    componentDidMount () {
        try {
            // TODO: Load portfolio items
            firestore
                .collection('portfolio')
                .get()
                .then(snapshot => {
                    const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    this.setState({
                        filtered: false,
                        filteredItems: items,
                        items
                    });
                });
        } catch (error) {
            console.log(`Error loading portfolio items: ${error}`);
        }
    }

    // Handle filtering the portfolio items
    filterItems = (e, name) => {
        // Prevent the page from performing a hard-refresh
        e.preventDefault();

        // Extract the portfolio items from the state
        const { items } = this.state;

        // If the user selects 'all', don't filter the results
        if (name === 'all') {
            this.setState({ filtered: false, filteredItems: items });
        } else {
            // Use the JS filter function to figure out what items need removed
            let filteredItems = items.filter(item => {
                // Check if the current portfolio item matches the category filter
                if (name === item.category) {
                    // Return the portfolio item
                    return item;
                }
            });

            this.setState({ filtered: true, filteredItems });
        }
    };

    render () {
        // Extract the portfolio items from the state
        const { filteredItems } = this.state;

        return (
            <div>
                <HeaderBar />

                <header
                    className="header text-white bg-img"
                    style={{
                        backgroundColor: '#212652',
                        backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/portfolio.png)'
                    }}
                >
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h1 className="ft-tungsten-md" style={{ fontSize: '65px' }}>PORTFOLIO</h1>
                            </div>
                        </div>
                    </div>
                </header>

                <main className="main-content">
                    <section className="section bg-white">
                        <div className="container">
                            <div data-provide="shuffle">
                                <ul className="nav nav-center nav-bold nav-uppercase nav-slash mb-7" data-shuffle="filter">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#" data-shuffle="button" onClick={e => this.filterItems(e, 'all')}>All</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" data-shuffle="button" data-group="box" onClick={e => this.filterItems(e, 'publications')}>Publications</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" data-shuffle="button" data-group="book" onClick={e => this.filterItems(e, 'maps')}>Maps</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" data-shuffle="button" data-group="bottle" onClick={e => this.filterItems(e, 'imaps')}>iMaps</a>
                                    </li>
                                </ul>

                                <FlipMove className="row gap-y gap-2">
                                    {filteredItems.map(item => (
                                        <div
                                            key={uuid()}
                                            className="col-4 col-lg-2"
                                            data-shuffle="item"
                                            data-groups={item.category}
                                        >
                                            <Link
                                                to={`/portfolio/${item.id}`}
                                                className="portfolio-1"
                                                style={{ backgroundColor: '#9190ac30' }}
                                            >
                                                <img src={item.thumb} alt={`${item.id} thumbnail`} />
                                                <div className="portfolio-detail">
                                                    <h5>{item.name}</h5>
                                                    <p>{item.category}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </FlipMove>
                            </div>
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default PortfolioPage;
