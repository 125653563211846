import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

import Slider from 'react-slick';

import { firestore } from '../../firebase/firebase.utils';

class PortfolioItemPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            name: '',
            thumb: '',
            desc: '',
            industry: '',
            solution: '',
            client: '',
            year: '',
            linkTitle: '',
            linkURL: '',
            images: []
        };
    }

    componentDidMount () {
        try {
            firestore
                .collection('portfolio')
                .doc(this.props.match.params.id)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        this.setState({ ...doc.data() });
                    }
                })
        } catch (error) {

        }
    }

    render () {
        // Extract the data from the state
        const { name, desc, industry, solution, client, year, linkTitle, linkURL, images } = this.state;

        return (
            <div>
                <HeaderBar />

                <header className="header text-center bg-white">
                    <div className="container">
                        <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}>{name}</h1>
                    </div>
                </header>

                <main className="main-content bg-white">
                    <section className="section">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-8">

                                    <Slider slidesToShow={1} slidesToScroll={1} dots={false} infinite={true} speed={2000} arrows={true} autoplay={true}>
                                        {images.map(slide => (<div><img src={slide} alt="..." className="mb-1" /></div>))}
                                    </Slider>

                                    {/*
                                    <div className="gallery gallery-4-type1">
                                        <a className="gallery-item" href="#">
                                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/portfolio/4.jpg" alt="..." />
                                        </a>
                                        <a className="gallery-item" href="#">
                                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/portfolio/4.jpg" alt="..." />
                                        </a>
                                        <a className="gallery-item" href="#">
                                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/portfolio/4.jpg" alt="..." />
                                        </a>
                                        <a className="gallery-item" href="#">
                                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/portfolio/4.jpg" alt="..." />
                                        </a>
                                    </div>
                                    */}
                                </div>

                                <div className="col-md-4">
                                    <ul className="project-detail">
                                        <li>
                                            <strong className="ft-tungsten-md" style={{ fontSize: '20px' }}>Industry</strong>
                                            <span className="ft-gotham-book">{industry || 'Unknown Industry'}</span>
                                        </li>

                                        <li>
                                            <strong className="ft-tungsten-md" style={{ fontSize: '20px' }}>Solution</strong>
                                            <span className="ft-gotham-book">{solution || 'Unknown Solution'}</span>
                                        </li>

                                        <li>
                                            <strong className="ft-tungsten-md" style={{ fontSize: '20px' }}>Client</strong>
                                            <span className="ft-gotham-book">{client || 'Unknown Client'}</span>
                                        </li>

                                        {linkURL &&
                                            <li>
                                                <strong className="ft-tungsten-md" style={{ fontSize: '20px' }}>Link</strong>
                                                <span className="ft-gotham-book"><a href={linkURL} target="_blank">{linkTitle}</a></span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default PortfolioItemPage;
