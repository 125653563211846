import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const PrintingServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/printing-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>PRINTING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                Lunar Cow Publishing has a print network of over 750 printers in the United States. We exclusively use G7 master- certified printers for our publications. These master-certified printers use heat web offset presses that produce the highest quality products as well as provide great efficiency and the most innovation in the printing industry.
                            </p>
                            <p>
                                Innovation is critical when printing our publications. We provide the highest quality printing services with a focus on the paper, ink, binding, paper coatings, manufacturing process and delivery of the product.
                            </p>
                            <p>
                                We complement our print network with your local and regional printers so that we can keep the economics and printing business local whenever possible.
                            </p>
                            <p>
                                Lunar Cow Publishing utilizes a soft proof-hard proof-soft proof process to ensure that the information in your guide is as up-to-date and accurate as possible. You will receive a soft proof of your publication that can be easily edited until all copy and design is approved. Once approved, your publication will be sent to the printer, which will produce the hard copy proof.
                            </p>
                            <p>
                                Printing and delivery are the final, critical stages of this campaign. Color-accurate proofs will be provided to you for final review. Once approved, the publication will be printed and delivered.
                            </p>
                            <p>
                                We work with you so that we can determine the exact address for delivery, hours of operation, contact person, cell phone information and any special delivery instructions there might be for the project. The freight carriers will have the proper equipment for delivery including but limited to pallet jacks, lift gates and inside delivery.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default PrintingServicePage;
