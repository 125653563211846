import React, { useEffect } from 'react';
import { Router, Route, Switch, useLocation } from 'react-router-dom';

// Import google analytics
import Analytics from 'react-router-ga';

// Import landing pages
import IndexPage from '../containers/landing-pages/IndexPage';
import GetQuotePage from '../containers/landing-pages/GetQuotePage';
import ScheduleDemoPage from '../containers/landing-pages/ScheduleDemoPage';
import TosPage from '../containers/landing-pages/TosPage';
import ReleaseFormPage from '../containers/landing-pages/ReleaseFormPage';

// About sections
import StoryPage from '../containers/landing-pages/StoryPage';
import FarmPage from '../containers/landing-pages/FarmPage';
import FarmhandsPage from '../containers/landing-pages/FarmhandsPage';
import PhilosophyPage from '../containers/landing-pages/PhilosophyPage';
import PublishingPage from '../containers/landing-pages/PublishingPage';
import BlueRibbonsPage from '../containers/landing-pages/BlueRibbonsPage';
import ReelPage from '../containers/landing-pages/ReelPage';

// Corporate Brochure
import BrochurePage from '../containers/landing-pages/BrochurePage';

// Process page and sub-pages
import ProcessPage from '../containers/landing-pages/ProcessPage';
import AdSalesProcessPage from '../containers/process/AdSalesProcessPage';
import BarnstormingProcessPage from '../containers/process/BarnstormingProcessPage';
import DeliveryProcessPage from '../containers/process/DeliveryProcessPage';
import EditorialProcessPage from '../containers/process/EditorialProcessPage';
import ImagesProcessPage from '../containers/process/ImagesProcessPage';
import PrintingProcessPage from '../containers/process/PrintingProcessPage';
import ProjectManagementProcessPage from '../containers/process/ProjectManagementProcessPage';
import ProofingProcessPage from '../containers/process/ProofingProcessPage';
import TimelinesProcessPage from '../containers/process/TimelinesProcessPage';

// Services page and sub-pages
import ServicesPage from '../containers/landing-pages/ServicesPage';
import AdvertisingSalesServicePage from '../containers/services/AdvertisingSalesServicePage';
import CreativeDesignServicePage from '../containers/services/CreativeDesignServicePage';
import DigitalPublishingServicePage from '../containers/services/DigitalPublishingServicePage';
import ImapServicePage from '../containers/services/ImapServicePage';
import KiosksServicePage from '../containers/services/KiosksServicePage';
import MarketsServicePage from '../containers/services/MarketsServicePage';
import PrintingServicePage from '../containers/services/PrintingServicePage';
import PublishingServicePage from '../containers/services/PublishingServicePage';
import WebDevelopmentServicePage from '../containers/services/WebDevelopmentServicePage';

//
import FiveTrendsPage from '../containers/landing-pages/FiveTrendsPage';
import TenStepsPage from '../containers/landing-pages/TenStepsPage';
import EmailMetricsPage from '../containers/landing-pages/EmailMetricsPage';

// Import blog pages
import BlogPage from '../containers/blog/BlogPage';
import BlogItemPage from '../containers/blog/BlogItemPage';

// Import portfolio pages
import PortfolioPage from '../containers/portfolio/PortfolioPage';
import PortfolioItemPage from '../containers/portfolio/PortfolioItemPage';

// Import authentication pages
import LoginPage from '../containers/auth/LoginPage';
import PasswordRecoveryPage from '../containers/auth/PasswordRecoveryPage';

// Import error pages
import NotFoundPage from '../containers/errors/NotFoundPage';

// Import the history components
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

// Define the app router
const AppRouter = () => (
    <Router history={history}>
        <ScrollToTop />
        <div>
            <Analytics id="UA-151991346-1">
                <Switch>
                    <Route path="/" exact component={IndexPage} />

                    <Route path="/story" exact component={StoryPage} />
                    <Route path="/farm" exact component={FarmPage} />
                    <Route path="/farmhands" exact component={FarmhandsPage} />
                    <Route path="/philosophy" exact component={PhilosophyPage} />
                    <Route path="/publishing" exact component={PublishingPage} />
                    <Route path="/blue-ribbons" exact component={BlueRibbonsPage} />
                    <Route path="/reel" exact component={ReelPage} />

                    <Route path="/corporate-brochure" exact component={BrochurePage} />

                    <Route path="/process" exact component={ProcessPage} />
                    <Route path="/process/advertising-sales" exact component={AdSalesProcessPage} />
                    <Route path="/process/barnstorming" exact component={BarnstormingProcessPage} />
                    <Route path="/process/delivery" exact component={DeliveryProcessPage} />
                    <Route path="/process/editorial" exact component={EditorialProcessPage} />
                    <Route path="/process/images" exact component={ImagesProcessPage} />
                    <Route path="/process/printing" exact component={PrintingProcessPage} />
                    <Route path="/process/project-management" exact component={ProjectManagementProcessPage} />
                    <Route path="/process/proofing" exact component={ProofingProcessPage} />
                    <Route path="/process/timelines" exact component={TimelinesProcessPage} />

                    <Route path="/services" exact component={ServicesPage} />
                    <Route path="/services/advertising-sales" exact component={AdvertisingSalesServicePage} />
                    <Route path="/services/creative-design" exact component={CreativeDesignServicePage} />
                    <Route path="/services/digital-publishing" exact component={DigitalPublishingServicePage} />
                    <Route path="/services/imap" exact component={ImapServicePage} />
                    <Route path="/services/kiosk" exact component={KiosksServicePage} />
                    <Route path="/services/market" exact component={MarketsServicePage} />
                    <Route path="/services/printing" exact component={PrintingServicePage} />
                    <Route path="/services/publishing" exact component={PublishingServicePage} />
                    <Route path="/services/web-development" exact component={WebDevelopmentServicePage} />

                    <Route path="/5-deadliest-trends" exact component={FiveTrendsPage} />
                    <Route path="/10-steps" exact component={TenStepsPage} />
                    <Route path="/email-metrics" exact component={EmailMetricsPage} />

                    <Route path="/portfolio" exact component={PortfolioPage} />
                    <Route path="/portfolio/:id" exact component={PortfolioItemPage} />

                    <Route path="/tos" exact component={TosPage} />
                    <Route path="/businessterms" exact component={TosPage} />

                    <Route path="/release-form" exact component={ReleaseFormPage} />

                    <Route path="/get-quote" exact component={GetQuotePage} />
                    <Route path="/schedule-demo" exact component={ScheduleDemoPage} />

                    <Route path="/blog" exact component={BlogPage} />
                    <Route path="/blog/:slug" exact component={BlogItemPage} />

                    <Route path="/auth/login" exact component={LoginPage} />
                    <Route path="/auth/recover" exact component={PasswordRecoveryPage} />

                    <Route component={NotFoundPage} />
                </Switch>
            </Analytics>
        </div>
    </Router>
);

export default AppRouter;
