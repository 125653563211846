import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ImagesProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>IMAGES</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-photo-selection.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-photo-selection.png" alt="Images Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                If photography services are required, the photographer will be responsible for the primary and secondary images required for the publication. We will contract with a local photographer for this project. The photographer will conduct a Barnstorming Summit meeting with you to gain an understanding of your photography requirements for the publication. The Barnstorming Summit outline document will be used to identify the type of photography required for your publication. It is vital to the success of the photography being developed for your project that you specify the type of images you want in your publication.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ImagesProcessPage;
