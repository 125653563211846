import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ImapServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/imap-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>IMAP</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                The Lunar Cow iMap 3.0 is a mobile interactive technology that allows users to plan their trips, build itineraries, access calendars of events and get turn-by-turn directions while accessing points of interest (POI) data from businesses within the community or region that is being traveled. The iMap 3.0 is a feature-rich mapping software that assists the community, chamber members, visitors, and general audience visiting your region. The iMap 3.0 is the perfect product to enhance the user experience for travelers viewing your community map while planning their trip or for members of your community wanting to learn more or find something new.
                            </p>
                            <p>
                                The iMap is built using responsive design and HTML 5, which allows the software to automatically resize the screen real estate of the content to maximize viewing.
                            </p>
                            <p>
                                The HTML 5 toolbar and interface intuitively guides users through your community map. Simply clicking or tapping anywhere on the community map accesses the features of the iMap. Easily move between pages based on the navigation configuration of your user’s device. Mouse-enabled desktop devices utilize clicking, while mobile devices detect finger swiping.
                            </p>
                            <p>
                                The Lunar Cow Publishing iMap 3.0 offers a mobile interactive experience that will delight visitors, chamber members and the overall community with the ability to easily find resources they need and allow the user to interface with the businesses like never before.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ImapServicePage;
