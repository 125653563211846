import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBvvVwTjvXkvkRIK_Is5kBAaBKnMC_hhAg",
    authDomain: "lunar-cow-publishing.firebaseapp.com",
    databaseURL: "https://lunar-cow-publishing.firebaseio.com",
    projectId: "lunar-cow-publishing",
    storageBucket: "lunar-cow-publishing.appspot.com",
    messagingSenderId: "78561151090",
    appId: "1:78561151090:web:38890e66c3087b32129aeb",
    measurementId: "G-NKWNWKPGFL"
};

// Initialize the firebase app with our config
firebase.initializeApp(config);

// Enable persistence
firebase.firestore().enablePersistence({ synchronizeTabs: true });

// Export auth-related imports
export const auth = firebase.auth();

// Export firestore
export const firestore = firebase.firestore();

// Export functions
export const functions = firebase.functions();

// Export storage
export const storage = firebase.storage();

// Export our google auth provider
// export const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });

// Export firebase/app
export default firebase;
