import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ProcessPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/process.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>OUR PROCESS</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white bt-1">
                <div className="container">
                    <div className="row gap-y">

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/barnstorming" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-barnstorming-thumb.png" alt="Barnstorming Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/barnstorming">BARNSTORMING</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/advertising-sales" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-advertising-model-thumb.png" alt="Ad Sales Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/advertising-sales">ADVERTISING SALES</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/project-management" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-project-management-thumb.png" alt="Project Management Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/project-management">PROJECT MANAGEMENT</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/proofing" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-proofing-thumb.png" alt="Proofing Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/proofing">PROOFING</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/printing" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-printing-thumb.png" alt="Printing Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/printing">PRINTING</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/delivery" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-shipping-thumb.png" alt="Delivery Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/delivery">DELIVERY</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/editorial" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-copy-submission-thumb.png" alt="Copy Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/editorial">EDITORIAL</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/images" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-photo-selection-thumb.png" alt="Images Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/images">IMAGES</Link></h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="text-center">
                                <Link to="/process/timelines" className="product-media">
                                    <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/infographics/timeline-thumb.png" alt="Timelines Thumbnail" />
                                </Link>

                                <div>
                                    <h6 className="ft-tungsten-md" style={{ fontSize: '26px' }}><Link to="/process/timelines">TIMELINES</Link></h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ProcessPage;
