import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const FarmPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/farm.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>THE FARM</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>The location in which we operate Lunar Cow Publishing is called "The Farm.” This is where we plant ideas, nurture them to grow and hire the right farmhands to make sure we meet and surpass our clients' expectations.</p>
                            <p>Our office building is located in the Portage Lakes, Ohio area, sandwiched between a beautiful body of water and a championship golf course, Turkeyfoot Golf Club. We operate from a wonderful venue located within a region that complements our farm.</p>
                            <p>We embrace our people, processes and technologies that bring competitive solutions to our clients on a continuous basis. Our staff members are experts in graphic design, copywriting, computer programming, advertising sales, project management, process control and client services.</p>
                            <p>We have a customer-centric philosophy and work tirelessly to exceed the needs of our clients. Because we are a small, hard-working company, it allows us to be quick, innovative, responsive, interactive, and mobile-focused. We strive to under-promise and over-deliver upon our commitments, products, and services we offer. Our core focus is to delight our clients each time we engage them.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default FarmPage;
