import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const DigitalPublishingServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/digital-publishing-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>DIGITAL PUBLISHING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                The Lunar Cow GoGuide is an interactive, digital publishing product that is designed for desktop and mobile users who are looking to interact with your publication’s digital edition. Your members, visitors and community will be able to view your visitor guides, activity guides, community guides, business directories, resource directories and community maps easily on their smartphones, tablets, laptops, and desktops like never before.
                            </p>
                            <p>
                                The GoGuide incorporates page-flipping technology to enhance the users' experience for your publication. It is built using responsive design and HTML 5 that will automatically resize the real estate of the content to maximize viewing for any device the viewer is using.
                            </p>
                            <p>
                                The HTML 5 toolbar and interface intuitively guides readers through your digital edition. Simply clicking or tapping anywhere on the digital edition accesses the robust features of the GoGuide. Easily move between pages based on the navigation configuration of your users’ device. Mouse-enabled desktop devices are provided with left and right arrows for clicking, while mobile devices detect finger swiping.
                            </p>
                            <p>
                                The GoGuide can be converted into seven different languages, allows for the sharing of articles or the entire digital edition and can incorporate live video.  All advertisements are hyperlinked and go directly to the advertiser's website in one click. The GoGuide also has a complete analytics package for detailed reporting as well as page previewing features that allow a user to view pages before going directly to the page of their interest.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default DigitalPublishingServicePage;
