import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const PrintingProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>PRINTING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-printing.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-printing.png" alt="Printing Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                            Lunar Cow Publishing has a print network of over 750 printers in the United States. We use G7 certified master printers for our publications. These printers have heatset web offset presses that produce the highest quality products as well as provide efficiency and innovation. We complement our print network with your local and regional printers so that we can keep the economics and printing business local.
                            </p>
                            <p>
                                Lunar Cow Publishing utilizes a soft proof-hard proof-soft proof process to ensure that we identify and correct as many errors as we can collectively find in your publication. You will receive a soft proof of your publication, and we will work that soft proof until you approve it to be final. Once you approve the soft proof, it will be sent to the printer so that we can receive the hard copy proof.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default PrintingProcessPage;
