import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const TenStepsPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>10 STEPS</strong></h1>
            </div>
        </header>

        <section className="section pt-5 pb-5" style={{ backgroundColor: '#2780b9' }}>
            <div className="container">
                <div className="row gap-y align-items-center">
                    <div className="col-md-9">
                        <h4 className="mb-0 text-center text-md-left text-white fw-500 ft-gotham-book">View the 10 Steps Report in PDF format</h4>
                    </div>

                    <div className="col-md-3 text-center text-md-right">
                        <a className="btn btn-lg btn-round btn-light ft-gotham-book" href="http://www.lunarcow.com/uploads/10_steps.pdf" target="_blank">Open PDF</a>
                    </div>
                </div>
            </div>
        </section>

        <main className="main-content">
            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-1.png" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>BROKER A COHESIVE UNDERSTANDING BETWEEN CLIENT &amp; PUBLISHER</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish clearly defined strategic and tactical objectives</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Create communication expectations and syntax understanding</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Understand the timeline and follow through with deliverables</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Attend weekly and monthly review meetings</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Produce “Key Performance Indicators” (KPIs) for both publisher and client</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-2.png" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>ALLOW BOTH TEAMS TO COLLABORATE TOGETHER EFFICIENTLY</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Produce a parallelism between teams to create a purposeful union that produces successful results</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Be proactive and innovative with strategy meetings</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Be open to accept new ideas, vision, purpose, and overall direction</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish collaboration sessions to account for achievement of milestones</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Engage milestones that have specific action items associated with all development procedures</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Research current trends and evaluate national standards</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Support a detailed outline of topics to help facilitate the design</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-3.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>CREATE A VALUE PROPOSITION THAT HELPS TO DIFFERENTIATE THE SALES OFFERING</h2>
                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Create a compelling sales and marketing message</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Obtain marketing lists with qualified and targeted prospects</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Utilize direct mail, email, web, and telephone marketing to produce effective results</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Integrate a contact management or CRM system to track and measure productivity</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-4.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>CREATE AN ENCHANTING, VIVID COVER TO PULL THE VIEWER INSIDE THE PUBLICATION</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Create a cover theme that will complement internal body pages</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Design a captivating masthead that will stand out amongst the clutter of other publications</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Feature imagery that encompasses the area’s activities and interests</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Position primary images and secondary images to create contrast</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Consider unique paper alternatives</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-5.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>CREATE APPEALING, INNOVATIVE LAYOUTS THAT WILL CAPTIVATE THE AUDIENCE</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish proper ratios for advertising, editorial and imagery</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Use big, bold images and minimum copy</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Carry page styles from the cover to back page</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Develop a color palette that is used consistently throughout</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Determine consistent header, paragraph, and footer treatments</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Incorporate oversized hero imagery to enhance detail</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-6.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>USE IMAGERY OF LOCAL, EYE-CATCHING SURROUNDINGS TO AMPLIFY THE DESTINATION</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Select high-quality, professional images that feature vibrant colors to entice the traveler</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Develop an image library that contains a mixture of both stock and local photography</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Use high-resolution images for the best quality of printing results</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Consider retaining a regional photographer</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-7.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>CRAFT SIMPLE YET SIGNIFICANT EDITORIAL TO ENGAGE THE READERS</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish editorial guidelines for word count</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Publish content that contains original and up-to-date information</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Create unique feature stories</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Use little-known facts to surprise and delight the audience</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Develop insets that complement the primary feature stories</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Cover a wide variety of attractions, making the informational map user-friendly</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-8.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>REACH THE VAST AMOUNT OF EXCURSIONISTS TO ENHANCE THE DESTINATION MESSAGE</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish a cohesive marketing plan that includes focus on leisure, convention, sport, group travel and travel professionals</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Integrate a robust responsive mobile digital publishing solution</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Create a responsive microsite online to message and market to predetermined vertical markets</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Connect with the masses through targeted social media</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Utilize organic search and ad words for keywords and keyword phrases to target potential visitors</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-9.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>PROOFING IS A MUST TO ENSURE THAT STANDARDS ARE FOLLOWED</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Establish adequate time to review the full publication</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Incorporate an automated proofing system that controls quality and establishes an easy-to-engage process</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Utilize both soft (online) and hard copy (print) proofs to complement the overall proofing process</span>
                            </p>

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> Use color-accurate printer hard copy proofs for final process approval</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/10-steps/header-10.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>PRODUCING A PUBLICATION IS AN EXCITING PROCESS, SO HAVE SOME FUN WITH IT!</h2>

                            <br />

                            <p>
                                <i className="ti-check text-success mr-2" />
                                <span> That’s all, folks!</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default TenStepsPage;
