import React from 'react';
import uuid from 'uuid';

import { Link } from 'react-router-dom';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

import { firestore } from '../../firebase/firebase.utils';

class BlogPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            posts: []
        };
    }

    componentDidMount () {
        try {
            // Load blog posts
            firestore
                .collection('blog')
                .orderBy('date', 'desc')
                .limit(9)
                .get()
                .then(snapshot => {
                    const posts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    this.setState({ posts });
                });
        } catch (error) {
            console.log(`Error loading blog posts: ${error}`);
        }
    }

    render () {
        const { posts } = this.state;

        return (
            <div>
                <HeaderBar />

                <main className="main-content">
                    <section className="section bg-white">
                        <div className="container">
                            <div className="row gap-y">

                                {/** TODO: Truncate title to 50 characters here */}

                                {posts.map(post => (
                                    <div className="col-md-6 col-lg-4" key={uuid()}>
                                        <div className="card d-block hover-shadow-6 mb-6" style={{ border: '1px solid #bbb', height: '100%' }}>
                                            <Link to={`/blog/${post.slug}`}>
                                                <img className="card-img-top" src={post.thumb} alt={`${post.title} Thumbnail`} />
                                            </Link>
                                            <div className="p-6 text-center">
                                                <p className="small-5 text-lighter text-uppercase ls-2 fw-400">{post.category}</p>
                                                <h5 className="mb-0">
                                                    <Link to={`/blog/${post.slug}`} className="text-dark ft-gotham-book">
                                                        {post.title}
                                                    </Link>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <nav className="flexbox mt-6">
                                <a className="btn btn-white disabled"><i className="ti-arrow-left fs-9 mr-2"></i> Newer</a>
                                <a className="btn btn-white" href="#">Older <i className="ti-arrow-right fs-9 ml-2"></i></a>
                            </nav>
                        </div>
                    </section>
                </main>

                <PreFooter />
                <Footer />
            </div>
        );
    }
}

export default BlogPage;
