import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { auth, firestore } from '../../firebase/firebase.utils';
import ls from 'local-storage';

class LoginPage extends React.Component {
    constructor (props) {
        super(props);

        // Store the state for the login page
        this.state = {
            email: '',
            password: '',
            error: ''
        };
    }

    componentDidMount () {
        // TODO: Check if the user is already authenticated, and
        // redirect them to the appropriate dashboard
    }

    // Handle trying to authenticate the user
    login = e => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        const { email, password } = this.state;

        auth
            .signInWithEmailAndPassword(email, password)
            .then(data => {
                // Capture the data returned from a successful login
                const user = data.user;

                // Grab a reference to the logged in user based on their UserID
                const userRef = firestore.doc(`users/${user.uid}`);

                // Set the user ID and other info that comes packaged with auth()
                ls.set('uid', user.uid);
                ls.set('refreshToken', user.refreshToken);
                ls.set('email', user.email);

                // Grab more data on the user and store it in local storage
                userRef.get().then(snapshot => {
                    ls.set('accType', snapshot.data().accType);
                    ls.set('accSubType', snapshot.data().accSubType);

                    // TODO: Redirect the user to the appropriate
                    // portal based on their user level

                    /*
                    // Only update the image if there is one in the system
                    if (snapshot.data().imgProfile) {
                        ls.set('imgProfile', snapshot.data().imgProfile);
                    }

                    ls.set('name', snapshot.data().name);

                    // Clear out the email and password field for security reasons
                    this.setState({ email: '', password: '' });
                    */

                    // TODO: Only after we get the data do we load the dashboard in the appropriate portal
                    // this.props.history.replace('/dashboard');
                });
            })
            .catch(err => { this.setState({ error: `Invalid login credentials. ${err}` }); });
    };

    render() {
        // Extract the email, password, and any errors from the state
        const { email, password, error } = this.state;

        // <div className="layout-centered" style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/patterns/interlaced.png)', backgroundRepeat: 'repeat' }}>

        return (

            <div className="layout-centered bg-img" style={{ backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/images/auth/farm.png)' }}>
                <main className="main-content" style={{ height: '100vh' }}>
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <h5 className="mb-7">
                            <Link to="/">
                                <img
                                    className="logo-dark mr-3"
                                    src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/logo-dark.png"
                                    alt="Lunar Cow Publishing"
                                    style={{ height: '30px' }}
                                />
                            </Link>

                            Sign In
                        </h5>

                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    name="username"
                                    placeholder="Email"
                                    value={email || ''}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="password"
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    name="password"
                                    placeholder="Password"
                                    value={password || ''}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                                {error &&
                                    <div className="invalid-feedback">{error}</div>
                                }
                            </div>

                            <div className="form-group flexbox py-3">
                                <NavLink
                                    className="text-muted small-2 ml-auto"
                                    to="/auth/recover">
                                    Forgot Password?
                                </NavLink>
                            </div>

                            <div className="form-group">
                                <button
                                    className="btn btn-block btn-primary"
                                    onClick={this.login}>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        );
    }
}

export default LoginPage;
