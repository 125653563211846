import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const MarketsServicePage = () => (
    <div>
        <HeaderBar />

        {/* data-overlay="6" */}
        <header
            className="header text-white"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/markets-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>MARKETS</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                Lunar Cow Publishing is a destination marketing company that specializes in helping chambers of commerce and convention and visitors bureaus across the United States. We provide customized solutions to these markets, enabling them to bring more value to their members, visitors, and the communities they represent. Lunar Cow Publishing has a niche market focus that helps us deliver superior results for our clients.
                            </p>
                            <p>
                                Among the approximately 20,000 chambers of commerce and 8,000 convention and visitors bureaus in the country, Lunar Cow Publishing proudly supports over 400 of these specialized clients. Our national footprint consists of clients who range from small cities to large regions.
                            </p>
                            <p>
                                Our company continues to expand our market focus to include publishing relocation guides, community guides, visitor guides, business directories, activities guides, outdoor guides and dining guides as well as community maps. These core publishing services are complemented with our proprietary mobile interactive technologies, which provide leading-edge, core differentiation for our clients while bringing tremendous value to the members, visitors, and communities they represent.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default MarketsServicePage;
