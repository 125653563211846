import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const FiveTrendsPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>5 DEADLIEST TRENDS</strong></h1>
            </div>
        </header>

        <section className="section pt-5 pb-5" style={{ backgroundColor: '#2780b9' }}>
            <div className="container">
                <div className="row gap-y align-items-center">
                    <div className="col-md-9">
                        <h4 className="mb-0 text-center text-md-left text-white fw-500 ft-gotham-book">View the Five Deadliest Trends Report in PDF format</h4>
                    </div>

                    <div className="col-md-3 text-center text-md-right">
                        <a className="btn btn-lg btn-round btn-light ft-gotham-book" href="http://www.lunarcow.com/uploads/5_trends.pdf" target="_blank">Open PDF</a>
                    </div>
                </div>
            </div>
        </section>

        <main className="main-content">
            <section className="section text-white bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/5-deadliest-trends/header-1.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>A GOOD-QUALITY PRINTED COMMUNITY MAP STARTS WITH A HIGH-QUALITY MAP FILE</h2>
                            <p className="lead">
                                The core fundamentals to producing a good, quality printed community map are the map files themselves. It is important to make sure that when acquiring map files for your project that they are vector art, either AI or vector EPS-based. Having vector art files for your map will allow you to use the files in any manner needed to ensure that the design of the printed map is exactly what you want. Vector files will also ensure that you can rpurpose those map files for other map-related projects and if applicable provide them to partner organizations to use for their mapping engagements.
                            </p>
                            <p className="lead">
                                You will want to make sure that your map files are layered so that layers can be turned on and off at your discretion. You will then be able to choose the layers that best meet your needs for your map project, such as major highways, interstates, road arteries, points of interest and parks and recreation. The layers of your map files will help to create a printed community map that will render exactly what you want to appear. The layers will also be extremely helpful if you plan to allow other organizations to use your map files for similar projects.
                            </p>
                            <p className="lead">
                                Having indexes is another important fundamental to consider when obtaining map files. You will want to ensure that you have indexes for your county, as well as city pullouts or insets, that will complement your main mapping geography. It is important to make sure that you have the indexes for each mapping area that will be on your printed community map project.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/5-deadliest-trends/header-2.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>CATER TO THE NEEDS OF YOUR VISITORS, MEMBERS AND COMMUNITY MEMBERS</h2>
                            <p className="lead">
                                Providing a platform for your printed community map with added digital, mobile, and interactive disciplines will allow you to cater to the needs of all your visitors, members and community members. It is important to create a solution for your printed community map that allows a user to choose which platform best meets their individual needs.
                            </p>
                            <p className="lead">
                                Advanced functionality is now available in all disciplines like creating itineraries, producing sample itineraries, trip-planning options, highlighting points of interest, social media, calendars of events, photos, videos, trails, areas, and digital notes. These added features will assist in user satisfaction while driving traffic to support advertisers and local businesses.
                            </p>
                            <p className="lead">
                                The new mobile interactive technologies are where all the action is for users. These platforms offer advanced features, creating desirable options for users that want more of an experience than just working with your printed map. It is so very important to offer as many mobile interactive technologies as possible for your users of your mapping products.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/5-deadliest-trends/header-3.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>MAKE RATES AFFORDABLE AND ATTRACTIVE FOR YOUR POTENTIAL ADVERTISERS</h2>
                            <p className="lead">
                                Having advertising sales as a key fundamental to your printed community map project is important in achieving two common goals. The first goal is to have the printed community map be self-sustaining so that the revenue generated from advertising sales pays for the entire cost of producing the map. The second goal is to generate additional revenues from the advertising sales so that the excess funds generated from the project can be used for other purposes.
                            </p>
                            <p className="lead">
                                The golden rule to establishing advertising sales rates is to make the rates affordable and attractive for your potential advertisers. You can always increase rates in the future, so start out the project with very affordable rates. To attract the greatest number of advertisers to your printed community map project, you will need to package as many benefits to show as much value as you can. The integration of print, digital and interactive is very appealing and will yield the highest conversion rates for your prospective advertisers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/5-deadliest-trends/header-4.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>THE QUALITY OF YOUR MAP IS PARAMOUNT TO THE SUCCESS OF YOUR MAP CAMPAIGN</h2>
                            <p className="lead">
                                The quality of your printed community map is paramount to the success of your overall map campaign. Using a durable stock weight paper that has the proper coating is fundamental. Using a 70-to-80-pound paper is ideal with either glossy or matte finishes as standard. Check with your printers to identify new options that will help you differentiate your map compared to other competitive maps. Make sure that you work with your printer for proper folding of your printed community maps. Having the proper fold of your map is so very important as it relates to usability of your map for the consumers. Make it easy for your users to interface with your printed community map.
                            </p>
                            <p className="lead">
                                Certified master printers are the best option when printing your community map. When dealing with a master printer, you are ensuring the highest quality for your product. These printers will offer you the most advanced options as well as the greatest efficiencies in the printing industry. Master printers have the latest technologies, which means they have adapted the most current innovations in the printing industry, which equates to cost savings for you.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-white bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/reports/5-deadliest-trends/header-5.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-white" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <div className="container">
                    <div className="row gap-y align-items-center">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <h2 className="ft-tungsten-md" style={{ fontSize: '45px' }}>DISTRIBUTE YOUR MAP AT  KEY LOCATIONS WHERE YOUR MAP CAN BE PICKED UP AND UTILIZED</h2>
                            <p className="lead">
                                Making your printed community map available to all of your visitors, members and community members is paramount to the overall success of your campaign. Distribute your printed community map at key locations where your map can be picked up and utilized. These locations include, but are not limited, to hotels, motels, bed and breakfasts, campgrounds, resorts, information centers, airports, turnpikes, restaurants, and attractions. The more locations that you offer a user to access your printed community map, the greater the utilization will be. Promote your digital interactive mobile technologies in print, email, on your website, in your newsletter, through your social media and with all your affiliates.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default FiveTrendsPage;
