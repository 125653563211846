import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const BlueRibbonsPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/blue-ribbons.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>BLUE RIBBONS</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>Lunar Cow's creative work has been recognized locally, regionally, and nationally, both by our peers and within the industries we serve. The following is a list of awards, achievements, and recognition we have received over the years:</p>

                            <p><strong>Dual First Place in the 2017 Addy Competition for Fresno California's Visitors Guide.</strong></p>

                            <p>Best of Print and Public Service Publications</p>

                            <p><strong>First Place in the 2015 Middy Competition for Mohican-Loudonville Visitors Guide.</strong></p>

                            <p>The Ohio Association of Convention &amp; Visitors Bureaus (OACVB) held its 18th Annual Awards of Excellence Luncheon in Gahanna, Ohio on Thursday, September 17, 2015. This is where the Mohican-Loudonville Convention and Visitors Bureau was awarded First Place for the 2015 Mohican-Loudonville Visitors Guide.  MIDDY Awards are presented for destination marketing and tourism development. “The MIDDY Award name reflects our region of the country,” said Randie Adams, Chairperson of the OACVB MIDDY Awards for 2015. Recipients are recognized for excellence in objectivity, results, creativity, and originality.</p>

                            <p><strong>Gold Award in the MarCom 2013 Competition for Destination Irvine's 2013-2014 Official Visitors Guide.</strong></p>

                            <p>The MarCom Awards is an international creative competition that recognizes outstanding achievement by marketing and communication professionals. Entries come from corporate marketing and communication departments, advertising agencies, PR firms, design shops, production companies and freelancers. Over 6,500 entries from throughout the United States, Canada and several other countries were submitted for the 2013 competition.</p>

                            <p><strong>Citation of Excellence Award for the 2013 Clinton County Visitors Guide from the The Ohio Travel Association's RUBY Awards.</strong></p>

                            <p>The Ohio Travel Association's RUBY Awards recognize excellence in Ohio's travel and tourism industry for efforts in advertising, marketing, and public relations. The awards, sponsored by Ripple Effect Interactive, were presented during the Ohio Conference on Tourism, the state's largest conference for tourism industry professionals.</p>

                            <p><strong>Citation Award for the 2010 Clinton County Visitors Guide from the The Ohio Travel Association's RUBY Awards.</strong></p>

                            <p>The Ohio Travel Association's RUBY Awards recognize excellence in Ohio's travel and tourism industry for efforts in advertising, marketing, and public relations. The awards, sponsored by Ripple Effect Interactive, were presented during the Ohio Conference on Tourism, the state's largest conference for tourism industry professionals.</p>

                            <p><strong>Gold ADDY for LD Carlson</strong> – Brewer's Best Logo in the category of Elements of Advertising at the AAF- Akron 2010 ADDY Awards, 3-10.</p>

                            <p><strong>Silver ADDY for Six Flags Great America</strong> – Buccaneer Battle Media CD in the category of Interactive Media at the AAF-Akron 2010 ADDY Awards, 3-10.</p>

                            <p><strong>Silver ADDY for Six Flags Great Adventure</strong> – Fright Fest Coffin Media Kit in the category of Specialty Advertising at the AAF-Akron 2010 ADDY Awards, 3-10.</p>

                            <p><strong>Silver ADDY for Six Flags Discovery Kingdom</strong> – Odin e-portal in the category of Micro or Mini Sites at the AAF-Akron 2010 ADDY Awards, 3-10.</p>

                            <p><strong>Silver ADDY for Six Flags Great America</strong> – 2008 i-Press Kit in the category of Interactive Media at the Advertising Federation of Greater Akron 2009 ADDY Awards, 3-09.</p>

                            <p><strong>Best of Logo Design recognition in LogoLounge's Master Library: Animals & Mythology</strong> (RockPort Publishers) for Lunar Cow, YMCA's Jack-O-Lantern Jog event logo, Humane Society of Greater Akron, Humane for Hollywood event logo, In The Ruff golf outing logo, Six Flags Marine World's Sunset Safari, Taming Tigers and Shark XX golf outing logo.</p>

                            <p><strong>Silver ADDY for Six Flags Great Adventure</strong> – Thrills by Day, Chills by Night CD in the category of Interactive Media CD/DVD at the American Advertising Federation, 5th District ADDY Awards, 5-08.</p>

                            <p><strong>Silver ADDY for Six Flags Great Adventure</strong> – Thrills by Day, Chills by Night CD in the category of Interactive Media CD/DVD at the Advertising Federation of Greater Akron 2008 ADDY Awards, 3-08.</p>

                            <p><strong>Silver ADDY for Lunar Cow</strong> – 2007 Holiday e-Card in the category of Advertising Industry Self-Promotion at the Advertising Federation of Greater Akron 2008 ADDY Awards, 3-08.</p>

                            <p><strong>Silver ADDY for IMATA</strong> – 35th IMATA Conference Logo in the category of Elements of Advertising at the Advertising Federation of Greater Akron 2008 ADDY Awards, 3-08.</p>

                            <p><strong>Silver ADDY for Marineland</strong> – Marineland.net History Animation in the category of Animation or Special Effects, Internet at the Advertising Federation of Greater Akron 2008 ADDY Awards, 3-08.</p>

                            <p><strong>Silver ADDY for Carousel</strong> – West Side Story Logo in the category of Elements of Advertising at the Advertising Federation of Greater Akron 2007 ADDY Awards, 2-07.</p>

                            <p><strong>Silver ADDY for Carousel</strong> – I Love You Animation in the category of Special Effects, Internet at the Advertising Federation of Greater Akron 2007 ADDY Awards, 2-07.</p>

                            <p><strong>Best of Logo Design recognition in LogoLounge Volume 4</strong> (RockPort Publishers) for PFFL: Warriors, Bengals, and T.A. Bionics logos.</p>

                            <p><strong>Silver ADDY for SFMW</strong> – Try Adding Sharks! in the category of Collateral Material at the Advertising Federation of Greater Akron 2006 ADDY Awards, 2-06.</p>

                            <p><strong>Silver ADDY for Unicall Sales Kit</strong> in the category of Sales Promotion at the Advertising Federation of Greater Akron 2006 ADDY Awards, 2-06.</p>

                            <p><strong>Silver ADDY for SFEG</strong> – The EDGE CD-ROM in the category of Interactive Media at the Advertising Federation of Greater Akron 2006 ADDY Awards, 2-06.</p>

                            <p><strong>Silver ADDY for Humane for Hollywood Logo</strong> in the category of Elements of Advertising at the Advertising Federation of Greater Akron 2006 ADDY Awards, 2-06.</p>

                            <p><strong>Best of Logo Design recognition in, LogoLounge Volume 3</strong> (RockPort Publishers) for Shark 18 logo.</p>

                            <p><strong>First Place for Six Flags Over Texas</strong> – Spinning News Pop-Up in the category of Direct Response Campaign at the 2005 NODMA Eclipse Awards, 5-05.</p>

                            <p><strong>Second Place for Humane Society of Greater Akron</strong> – Grapes of Rrruff Invitation in the category of Direct Response Campaign at the 2005 NODMA Eclipse Awards, 5-05.</p>

                            <p><strong>Gold ADDY for Lunar Cow Corporate ID</strong> in the category of Advertising Industry Self-Promotion at the Advertising Federation of Greater Akron 2005 ADDY Awards, 3-05.</p>

                            <p><strong>Judges Choice Award for Lunar Cow Corporate ID</strong> in the category of Advertising Industry Self-Promotion at the Advertising Federation of Greater Akron 2005 ADDY Awards, 3-05.</p>

                            <p><strong>Silver ADDY for Lunar Cow Holiday Greeting</strong> in the category of Advertising Industry Self-Promotion at the Advertising Federation of Greater Akron 2005 ADDY Awards, 3-05.</p>

                            <p><strong>Silver ADDY for SummitHumane.org</strong> in the category of Public Service at the Advertising Federation of Greater Akron 2005 ADDY Awards, 3-05.</p>

                            <p><strong>Silver ADDY for Hurricane Harbor Invitation</strong> in the category of Collateral Material at the Advertising Federation of Greater Akron 2005 ADDY Awards, 3-05.</p>

                            <p><strong>Silver ADDY for Lunar Cow Corporate ID</strong> in the category of Advertising Industry Self-Promotion at the American Advertising Federation, 5th District ADDY Awards, 3-05.</p>

                            <p><strong>Certificate of Excellence for AntiqueVending.com</strong> in the category of Interactive Media at the Cleveland Advertising Association ADDY Awards, 1-05.</p>

                            <p><strong>Second Place for Carousel Dinner Theatre</strong> – Subscription Brochure in the category of Direct Response Campaign at the 2004 NODMA Eclipse Awards, 5-04.</p>

                            <p><strong>Second Place for Carousel Dinner Theatre</strong> – Smokey Joe's Café Media Invitation in the category of Direct Response Campaign at the 2004 NODMA Eclipse Awards, 5-04.</p>

                            <p><strong>Silver ADDY for Six Flags Marine World</strong> – Freak Me Out! Fright Fest press kit and postcard campaign, in the category of Direct Marketing at the American Advertising Federation, 5th District ADDY Awards, 3-04.</p>

                            <p><strong>Certificate of Excellence for MaggiesOrganics.com</strong> in the category of Interactive Media at the Cleveland Advertising Association ADDY Awards, 1-04.</p>

                            <p><strong>Certificate of Excellence for Six Flags Marine World</strong> – Get Looped! Media Invitation in the category of Special Event Material at the Cleveland Advertising Association ADDY Awards, 1-04.</p>

                            <p><strong>Certificate of Excellence for Six Flags Marine World</strong> – Freak Me Out! Fright Fest press kit and postcard campaign, in the category of Direct Marketing at the Cleveland Advertising Association ADDY Awards, 1-04.</p>

                            <p><strong>First place for Shark Scramble Invitation</strong> in the category of Direct Response Campaign at the NODMA Direct Marketing Awards, 5-03.</p>

                            <p><strong>Distinguished Excellence Award for Six Flags Elitch Gardens</strong> – The Flying Coaster press kit at the IAAPA Brass Ring Awards, 11-02.</p>

                            <p><strong>First place for Cedar Point</strong> – Flash Animations on CedarPoint.com in the category of Other Media at the NODMA Direct Marketing Awards, 5-02.</p>

                            <p><strong>Second place for Safelite</strong> - Browns Tailgate Invitation in the category of Solo Direct Mail at the NODMA Direct Marketing Awards, 5-02.</p>

                            <p><strong>Award of Excellence for LunarCow.com</strong> in the category of Other Media at the Southern Ohio Direct Marketing Association Apex Awards, 5-01.</p>

                            <p><strong>First place for Lunar Cow Three-Part Brochure Series</strong> in the category of Direct Response Campaign at NODMA Direct Marketing Awards, 5-01.</p>

                            <p><strong>Second Place for LunarCow.com</strong> in the category of Specialty Direct Marketing at NODMA Direct Marketing Awards, 5-01.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default BlueRibbonsPage;
