import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const ProofingProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>PROOFING</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-proofing.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-proofing.png" alt="Proofing Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Lunar Cow Publishing has a comprehensive proofing process that engages three distinct organizations to help ensure that your publication is error free. Our process entails soft proofing, hard proofing, and soft proofing. The design staff here on the Farm will soft proof your publication, and when an error is found they will correct it.
                            </p>
                            <p>
                                Once the complete internal proofing exercise is finished, Lunar Cow Publishing will engage the University of Akron, our local university, English and Communications departments to soft proof your publication. If any corrections are necessary, those changes will be made, and the publication will be updated.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default ProofingProcessPage;
