import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const KiosksServicePage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/services/headers/kiosks-header.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>KIOSKS</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>
                                The Lunar Cow Publishing Information Kiosk is a customer favorite. Our kiosks have a modern and sleek design concept with a 4K, 22” widescreen monitor set in portrait mode. The monitor draws attention and promotes user interaction; its generous viewable and workable area is perfect for software that utilizes an onscreen keyboard.
                            </p>
                            <p>
                                The Lunar Cow Kiosk retains customization flexibility while providing a large graphic area and housing an impressive amount of standard and optional components. Sophistication is reborn with the Lunar Cow Kiosk with its compact footprint, versatile configuration, and eye-catching aesthetics, making it an ideal solution for wayfinding, educational, and informational applications.
                            </p>
                            <p>
                                The Lunar Cow Publishing Kiosk has an overall footprint of 2.5 feet, operates using standard power that can be plugged into any outlet, weighs 350 pounds and can be mounted to any surface or be mobile and taken to events. The kiosk is powered by a dual-core processor with solid-state drives, can be added to a local area network or can operate using its wireless capabilities. The kiosk is controlled and protected using the KiowareTM kiosk software package, which comes standard in our kiosk unit.
                            </p>
                            <p>
                                The kiosk is a perfect solution for busy traffic areas or unmanned areas in which a self-service technology can provide information to tourists, visitors, members, partners, and the overall community.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default KiosksServicePage;
