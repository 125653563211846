import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const PhilosophyPage = () => (
    <div>
        <HeaderBar />

        <header
            className="header text-white bg-img"
            style={{
                backgroundColor: '#212652',
                backgroundImage: 'url(https://lunarcow.nyc3.cdn.digitaloceanspaces.com/lunarcow/headers/philosophy.png)'
            }}
        >
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>BUSINESS PHILOSOPHY</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto ft-gotham-book">
                            <p>Here on the farm, our ranchers and farmhands have a customer-centric business philosophy. Nothing exists without our clients as they are the center of what we do. Our systems, processes, people and technologies have been designed to champion the projects for our clients. We take great pride in collaborating with our clients to exceed expectations and to achieve breakthroughs in performance as well as results. We operate with a mission-driven process where building trust, working relationships, bonding and collaborations are pivotal to our success.</p>
                            <p>Every project begins with our "Barnstorming Kick-off," which is a fun and thought-provoking collaborative session that connects your team with ours, shaping the foundation for the mission.</p>
                            <p>Our 5-Step Mission Schedule gives you complete understanding of the development, procedures, performance results and timelines and ensures your complete satisfaction throughout the process.</p>
                            <p>Our clients are the center of our universe and we have built our process collaboration with you in mind. Your goals become our goals. Our business philosophy is simple: under-promise and over-deliver.</p>
                            <p>Our 1-to-1 project management system allows your team and ours to enjoy streamlined communication that keeps everyone on the same page. We have built our systems to have an intense focus on communication and client engagement. The greater our engagement, the higher level of project performance we can achieve.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default PhilosophyPage;
