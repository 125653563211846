import React from 'react';

import HeaderBar from '../../components/common/HeaderBar';
import PreFooter from '../../components/common/PreFooter';
import Footer from '../../components/common/Footer';

const EditorialProcessPage = () => (
    <div>
        <HeaderBar />

        <header className="header text-white" style={{ backgroundColor: '#212652' }}>
            <div className="container text-center">
                <h1 className="display-4 ft-tungsten-md" style={{ fontSize: '65px' }}><strong>EDITORIAL</strong></h1>
            </div>
        </header>

        <main className="main-content">
            <section className="section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-6 mb-md-0">
                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-copy-submission.png" target="_blank">
                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-copy-submission.png" alt="Editorial Infographic Thumbnail" />
                            </a>
                        </div>

                        <div className="col-md-4 ft-gotham-book">
                            <p>
                                Your project manager will create a working content outline for your publication which will be organized by page and section. This outline will allow for a concise mapping of your publication and will be used to keep your editorial well organized. We will audit your editorial and track each article, inset and sprinkle to make sure we have everything that is required for your publication.
                            </p>
                            <p>
                                Lunar Cow Publishing will work through our proofing process consisting of soft proofing, hard copy proofing and soft proofing. Our design team will work closely with you to ensure that whatever changes you would like to make to the editorial and overall publication will be made to your specifications. Your project manager will work with you to get all copy approved and ready to be placed into your publication.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <PreFooter />
        <Footer />
    </div>
);

export default EditorialProcessPage;
