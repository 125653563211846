import React from 'react';
import { Link } from 'react-router-dom';

const HeaderBar = () => (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-stick-dark stick" data-navbar="fixed">
        <div className="container">
            <div className="navbar-left mr-4">
                <button className="navbar-toggler" type="button">&#9776;</button>
                {/* https://lunarcow.nyc3.cdn.digitaloceanspaces.com/img/logo-dark.png  :: 50px */}
                <a className="navbar-brand" href="/">
                    <img
                        className="logo-dark"
                        src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/images/logos/lunarcow/LC-Publishing-logo-sm.png"
                        alt="Lunar Cow Publishing"
                        style={{ height: '35px' }}
                    />
                    <img
                        className="logo-light"
                        src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/images/logos/lunarcow/LC-Publishing-logo-sm.png"
                        alt="Lunar Cow Publishing"
                        style={{ height: '35px' }}
                    />
                </a>
            </div>

            <section className="navbar-mobile">
                <span className="navbar-divider d-mobile-none" />
                <nav className="nav nav-navbar mr-auto ft-tungsten-md" style={{ fontSize: '25px' }}>
                    {/* hover: #fff */}

                    <a
                        href="/"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        Home
                    </a>

                    <a
                        href="/#about"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        About
                    </a>

                    <Link
                        to="/services"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        Services
                    </Link>

                    <Link
                        to="/process"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        Process
                    </Link>

                    <a
                        href="/#presentations"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        Presentations
                    </a>

                    <a
                        href="/portfolio"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }}
                    >
                        Portfolio
                    </a>

                    <a
                        href="/#contact"
                        style={{
                            color: 'rgba(117,117,117,0.8)',
                            textTransform: 'uppercase',
                            wordSpacing: '2px',
                            paddingLeft: '1.125rem',
                            paddingRight: '1.125rem'
                        }
                }
                    >
                        Contact
                    </a>
                </nav>

                <div>
                    <Link
                        to="/auth/login"
                        className="btn btn-sm btn-round ml-lg-5 ft-tungsten-md"
                        style={{ fontSize: '15px', backgroundColor: '#71d0f6', color: '#fff' }}
                    >
                        Login
                    </Link>
                </div>
            </section>
        </div>
    </nav>
);

export default HeaderBar;
